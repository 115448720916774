import { ToggleOff, ToggleOn } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, IconButton, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { updateConference } from '../../../../utils/mutations/conferences';
import { Conference, User } from '../../../../utils/types';
import TableWidget from '../../../widgets/TableWidget';
import { dark_red, light_red } from '../../../../utils/colors';

interface Props {
  conference: Conference;
  registrants: User[];
}

interface Price {
  label: string;
  value: number;
}

export default function EditConferenceModal({ conference, registrants }: Props) {
  const [editing, setEditing] = useState(false);
  const [conferenceName, setConferenceName] = useState(conference?.conferenceName || '');
  const [prices, setPrices] = useState<Price[]>(
    Object.entries(conference?.prices || {}).map(([label, value]) => ({ label, value: value }))
  );

  // const resetEditingSection = () => {
  //   setPrices(Object.entries(conference?.prices || {}).map(([label, value]) => ({ label, value: value }));
  // }

  const handleUpdateConference = () => {
    if (!conferenceName.trim()) return alert("Conference name cannot be empty!");
    if (prices.some(({ label }) => !label.trim())) return alert("Price labels cannot be empty!");

    const password = prompt("Please enter your password to confirm changes:");
    if (password !== "admin123") return alert("Incorrect password!");

    try {
      const updatedPrices = prices.reduce((acc, { label, value }) => {
        acc[label] = value;
        return acc;
      }, {} as Record<string, number>);

      updateConference({ conferenceCode: conference.conferenceCode, conferenceName, prices: updatedPrices });
      setEditing(false);
    } catch (error: any) {
      alert(error.message);
    }
  };

  const handlePriceChange = (index: number, key: 'label' | 'value', value: string) => {
    setPrices((prev) =>
      prev.map((price, i) => {
        if (i !== index) return price; // Keep other prices unchanged
  
        if (key === 'value') {
          // Handle empty input gracefully and ensure only valid numbers are set
          const numValue = value === '' ? 0 : Number(value);
          if (isNaN(numValue) || numValue < 0) return price;
          return { ...price, value: numValue };
        }
  
        // For 'label', accept any string (but trim leading/trailing spaces)
        return { ...price, label: value.trim() };
      })
    );
  };
  
  const handleAddPrice = () =>
    setPrices((prev) => [
      ...prev,
      { label: "", value: 0 }, 
    ]);
  
  const handleRemovePrice = (index: number) => {
    if (prices.length > 1) {
      setPrices((prev) => prev.filter((_, i) => i !== index));
    }
  };

  const toggleClass = (index: number) => {
    // set the ticket class's price to the negative of its current value. If it is 0, set it to -Infinity
    // if it is -Infinity, set it to 0
    setPrices((prev) => {
      const newPrices = [...prev];
      newPrices[index].value = prev[index].value === 0 ? -Infinity : prev[index].value === -Infinity ? 0 : -prev[index].value;
      return newPrices;
    });
  };

  const renderEditingSection = () => (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '20px',
    }}>
      <TextField
        label="Conference Name"
        variant="outlined"
        value={conferenceName}
        onChange={(e) => setConferenceName(e.target.value)}
        size="small"
        sx={{ width: '75%'}}
      />
      {prices.map(({ label, value }, index) => (
        <Box key={index} sx={{ display: 'flex', flexDirection: 'row', 
        gap: 2, width: '75%' }}>
          <TextField
            label={`Class ${index + 1}`}
            disabled={value < 0}
            inputProps={{ maxLength: 1 }}
            value={label}
            onChange={(e) => handlePriceChange(index, 'label', e.target.value)}
            size="small"
            sx={{ width: '30%' }}
          />
          <TextField
            label={`Price ${index + 1}`}
            InputProps={{ startAdornment: '$' }}
            disabled={value < 0}
            value={value === -Infinity ? '0' : `${value < 0 ? value.toString().slice(1) : value}`}
            onChange={(e) => handlePriceChange(index, 'value', e.target.value)}
            size="small"
            sx={{ width: '30%' }}
          />
          <IconButton sx={{ width: '10%'}} onClick={() => toggleClass(index)}>
            {value < 0 ? <ToggleOff /> : <ToggleOn sx={{ color: dark_red }} />}
          </IconButton>
          <IconButton onClick={() => handleRemovePrice(index)}>
            <CloseIcon />
          </IconButton>
        </Box>
      ))}
      <Button onClick={handleAddPrice} sx={{ marginTop: 2 }}>Add Price</Button>
    </Box>
  );

  // create the conference rows but if it is red, make it red and remove the negative sign. If it is -Infinity, make it red and say "Free"
  const conferenceRows = prices.map(({ label, value }: Price) => {
    const registrantsCount = registrants.filter((user) => user.ticketClass === label).length;
    const price = (value === -Infinity  || value === 0)? "Free" : `$${value < 0 ? value.toString().slice(1) : value.toString()}`;
    const color = value < 0 || value === -Infinity ? light_red : undefined;

    const labelObj = <Typography sx={{ color }}>{label}</Typography>;
    const registrantsCountObj = <Typography sx={{ color }}>{registrantsCount}</Typography>;
    const priceObj = <Typography sx={{ color }}>{price}</Typography>;

    return [labelObj, registrantsCountObj, priceObj];
  });

  return (
    <Box sx={{ padding: 2, width: '50%', textAlign: 'center', justifySelf: 'center' }}>
      <Typography variant="h5" marginBottom={2}>
        {editing ? "Edit Conference" : "Ticket Classes"}
      </Typography>
      {editing ? renderEditingSection() : (
        <TableWidget headers={['Ticket Class', 'Registrants', 'Price']} rows={conferenceRows} search={false} tabs={false} />
      )}
      <Box sx={{ marginTop: 3 }}>
        <Button variant="outlined" onClick={editing ? handleUpdateConference : () => setEditing(true)}>
          {editing ? "Save" : "Edit"}
        </Button>
        {editing && <Button onClick={() => {
          setEditing(false);
          setConferenceName(conference.conferenceName);
          setPrices(Object.entries(conference.prices).map(([label, value]) => ({ label, value: value })));
        }} sx={{ marginLeft: 2 }}>Cancel</Button>}
      </Box>
    </Box>
  );
}
