import { Box, Tab, Tabs, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { dark_red } from "../../../utils/colors";
import type { User } from '../../../utils/types';
import Footer from '../menuBars/Footer';
import MenuBar from '../menuBars/MenuBar';
import PreferencesWidget from '../../widgets/PreferencesWidget';
import ProfileTab from './ProfileTab';
import PurchasesTab from './PurchasesTab';
import { PersonOutline, Tune, ShoppingCart } from "@mui/icons-material"; // Import icons

interface SettingsPageProps {
  user: User; 
  confType: 'main' | 'hyls';
  isSmallScreen: boolean;
}

export default function SettingsPage({ user, confType, isSmallScreen }: SettingsPageProps) {
  const [currentTab, setCurrentTab] = useState<number>(
    JSON.parse(sessionStorage.getItem('tab') || '0')
  );

  useEffect(() => {
    sessionStorage.setItem('tab', JSON.stringify(currentTab))
  }, [currentTab]);

  const handleChangeTab = (_: React.ChangeEvent<{}>, newValue: number) => {
    setCurrentTab(newValue);
  };

  return (
    <>
      <MenuBar user={user} confType={confType} isSmallScreen={isSmallScreen} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: "64px",
          minHeight: 'calc(100vh - 64px - 130px)',
          padding: "20px 0px",
        }}
      >
        <Typography
          variant="h3"
          sx={{
            textAlign: 'center',
            color: dark_red,
            fontWeight: 'bold',
          }}
        >
          Settings
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: isSmallScreen ? 'column' : 'row', // Change direction based on screen size
            padding: "20px",
          }}
        >
          {isSmallScreen ? ( // Render horizontal tabs on small screens
            <Tabs
              variant="scrollable"
              value={currentTab}
              onChange={handleChangeTab}
              sx={{
                '& .MuiTabs-flexContainer': {
                  justifyContent: 'center',
                },
                marginBottom: '20px',
              }}
            >
              <Tab label="Profile" icon={<PersonOutline />} value={0} />
              <Tab label="Preferences" icon={<Tune />} value={1} />
              <Tab label="Purchases" icon={<ShoppingCart />} value={2} />
            </Tabs>
          ) : ( // Render vertical tabs on larger screens
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={currentTab}
              onChange={handleChangeTab}

            >
              <Tab label="Profile" icon={<PersonOutline />} value={0} />
              <Tab label="Preferences" icon={<Tune />} value={1} />
              <Tab label="Purchases" icon={<ShoppingCart />} value={2} />
            </Tabs>
          )}
          <Box
            sx={{
              padding: "0px 20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {currentTab === 0 && (
              <ProfileTab isSmallScreen={isSmallScreen} />
            )}
            {currentTab === 1 && (
              user && <PreferencesWidget user={user}/>
            )}
            {currentTab === 2 && (
              user && <PurchasesTab user={user}/>
            )}
          </Box>
        </Box>
      </Box>
      <Footer />
    </>
  );
}
