import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { subscribeToConferences } from '../../../utils/mutations/conferences';
import { Conference, User } from '../../../utils/types';
import MenuBar from '../adminMenuBar/AdminMenuBar';
import AdminConferenceCard from './AdminConferenceCard';
import Footer from "../adminMenuBar/AdminFooter";

interface AdminHomeProps {
  user: User;
}

const AdminHome: React.FC<AdminHomeProps> = ({ user }) => {
  const [confs, setConfs] = useState<Conference[]>([]);

  // Function to load order from localStorage
  const loadOrderFromLocalStorage = (): string[] => {
    const storedOrder = localStorage.getItem('conferenceOrder');
    return storedOrder ? JSON.parse(storedOrder) : [];
  };

  // Function to save order to localStorage
  const saveOrderToLocalStorage = (order: string[]) => {
    localStorage.setItem('conferenceOrder', JSON.stringify(order));
  };

  // Apply cached order to the fetched conferences
  const applyOrder = (conferences: Conference[], order: string[]): Conference[] => {
    // Create a map for quick lookup of conference objects
    const conferenceMap = new Map(conferences.map(conf => [conf.conferenceCode, conf]));

    // Filter the stored order to include only currently available conferences
    const orderedConfs = order
      .map(code => conferenceMap.get(code))
      .filter(Boolean) as Conference[];

    // Find new conferences that are not in the stored order
    const unorderedConfs = conferences.filter(conf => !order.includes(conf.conferenceCode));

    // Return combined result: ordered first, then new conferences
    return [...orderedConfs, ...unorderedConfs];
  };

  // Fetch conferences and restore order from localStorage
  useEffect(() => {
    subscribeToConferences((data) => {
      const order = loadOrderFromLocalStorage();
      setConfs(applyOrder(data, order));
    });
  }, [user]);

  // Handle reordering of conference cards
  const moveCard = (dragIndex: number, hoverIndex: number) => {
    const updatedConfs = [...confs];
    const [draggedItem] = updatedConfs.splice(dragIndex, 1);
    updatedConfs.splice(hoverIndex, 0, draggedItem);
    setConfs(updatedConfs);

    // Update the cached order
    const newOrder = updatedConfs.map(conf => conf.conferenceCode);
    saveOrderToLocalStorage(newOrder);
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <Box>
        <MenuBar />
        <Box sx={{ marginTop: '64px', minHeight: 'calc(100vh - 64px - 110px)', justifyContent: 'center'}}>
          {user?.accessLevel === 1 && (
            <MuiAlert severity="warning" sx={{ borderRadius: '0px' }}>
              Attention! You are irreversibly editing the database directly with administrator privileges.
              Any changes can be highly destructive!
            </MuiAlert>
          )}
          <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: '20px', padding: '30px 10%' }}>
            {confs?.map((conf: Conference, index: number) => (
              <Grid item key={conf.conferenceCode} xs={8} sm={6} md={4} lg={4}>
                <AdminConferenceCard
                  conf={conf}
                  index={index}
                  moveCard={moveCard}
                />
              </Grid>
            ))}
          </Box>
        </Box>
        <Footer />
      </Box>
    </DndProvider>
  );
};

export default AdminHome;
