import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { dark_red } from "../../../utils/colors";
import { subscribeToProducts } from "../../../utils/mutations/products";
import { getUserProducts } from "../../../utils/mutations/users";
import { Product, User } from "../../../utils/types";

interface PurchasesTabProps {
  user: User;
}

interface Purchase extends Product {
  quantity: number;
}

const PurchasesTab = ({ user }: PurchasesTabProps) => {
  const [purchases, setPurchases] = useState<Purchase[]>([]);
  const [allProducts, setAllProducts] = useState<Product[]>([]);

  useEffect(() => {
    if (!user?.conferenceCode) return;
    const unsubscribe = subscribeToProducts(user.conferenceCode, (data: Product[]) => {
      setAllProducts(data);
    });
    return () => unsubscribe();
  }, [user.conferenceCode]);

  useEffect(() => {
    const fetchUserPurchases = async () => {
      try {
        const userProducts = await getUserProducts(user.uid);
        if (userProducts) {
          const productIds = Object.keys(userProducts);
          const userPurchases = allProducts
            .filter(product => product.id && productIds.includes(product.id))
            .map((product: any) => {
              const quantity = userProducts[product.id] || 0;
              return { ...product, quantity };
            });
          setPurchases(userPurchases);
        }
      } catch (error) {
        console.error("Error fetching user purchases:", error);
      }
    };
    fetchUserPurchases();
  }, [user, allProducts]);

  return (
    <Box>
      <Typography variant="h4" sx={{ marginBottom: '20px', }}>Purchases</Typography>
      {purchases.length > 0 ? (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Item</TableCell>
                <TableCell>Quantity</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {purchases.map((purchase) => (
                <TableRow key={purchase.id}>
                  <TableCell><img src={purchase.imageUrl} height="75px" alt={purchase.name} /></TableCell>
                  <TableCell>{purchase.name}</TableCell>
                  <TableCell>{purchase.quantity}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '20px',
            gap: '10px',
          }}
        >
          <ShoppingBagIcon sx={{ fontSize: '3rem', color: dark_red }} />
          <Typography variant="h6">You have no purchases.</Typography>
        </Box>
      )}
    </Box>
  );
};

export default PurchasesTab;