import { CssBaseline, ThemeProvider, Typography, useMediaQuery } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import React, { useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import './App.css';
import { auth } from './utils/firebase';
import { getUserData, syncUsers } from "./utils/mutations/users";

import ConfPage from './components/confPages/mainConfPage/ConfPage';
import MapPage from './components/confPages/mapPage/MapPage';
import Schedule from './components/confPages/schedule/Schedule';
import SettingsPage from './components/confPages/settings/Settings';
import Store from './components/confPages/store/Store';
import Home from './components/home/Home';

import HylsHome from './components/confPages/hylsPage/HylsHome';

import NetworkingPage from './components/NetworkingPage';

import AdminConference from './components/adminPage/adminConference/AdminConference';
import AdminGroups from './components/adminPage/adminGroups/adminGroups';
import AdminHome from './components/adminPage/adminHome/AdminHome';
import AdminUsers from './components/adminPage/adminUsers/AdminUsers';
import AdminPayments from './components/adminPage/adminPayments/adminPayments';

import type { User } from './utils/types';


// Create a theme instance for the entire app
const mdTheme = createTheme({
  palette: {
    primary: {
      main: "#770312"
    },
    secondary: {
      main: "#ffffff", // A white secondary color
    },
  },
  typography: {
    fontFamily: 'Poppins',
    h5: {
      fontSize: '1.5rem', // Customize the font size for h5 typography
    },
  },
  shape: {
    borderRadius: 8, // Adjust the global border radius
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none', // Override the all caps text transformation for all buttons
        },
      },
    },
  },
});



export default function App() {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [emailIsVerified, setEmailIsVerified] = useState<boolean>(false);

  const navigate = useNavigate();

  const hylsCodes = ["HYLS123", "VHYLS25", "HYLSXCL", "VCONF25"];

  const [confType, setConfType] = useState<'main' | 'hyls' | undefined>(undefined);
 
  /**
   * Handles user authentication state changes and synchronizes user data with the application.
   * 
   * This `useEffect` hook registers a Firebase Authentication observer to monitor changes
   * to the user's authentication state. Based on the user's state, it performs various actions,
   * including data synchronization, state updates, and navigation. When the component unmounts,
   * the observer is unregistered to prevent memory leaks.
   * 
   * Behavior:
   * 1. **User Signed Out or on Networking Pages**:
   *    - If the user is signed out, clears the `currentUser` state and redirects to the home page (`/`).
   *    - Skips redirection for networking-related routes (`/networking`).
   * 
   * 2. **User Signed In**:
   *    - **Database Synchronization**:
   *      - Ensures user data is synchronized with the database only if the user's `displayName` exists.
   *    - **Email Verification**:
   *      - Updates the `emailIsVerified` state to reflect the user's email verification status.
   *    - **Admin/Observer Handling**:
   *      - If the user's authentication token (`idTokenResult`) contains an `accessLevel` of 1 or 2, 
   *        identifies the user as an admin or observer.
   *      - Updates `currentUser` state with the user's database information and assigns the access level.
   *      - Redirects to the admin page (`/ADMIN`) if not already there.
   *    - **Regular User Handling**:
   *      - Retrieves user-specific data from the database and updates the `currentUser` state.
   *      - Checks if the user has a `conferenceCode`. Based on the code:
   *        - Determines the conference type (`hyls` or `main`) and updates the `confType` state.
   *        - If the user also has `preferences`, constructs a conference-specific path 
   *          (e.g., `/CONFCODE`) and redirects if not already on the appropriate page.
   * 
   * @param {Function} navigate - The React Router `navigate` function for page redirection.
   * @returns {void}
   */

  useEffect(() => {
    const handleAuthStateChange = async (user: any) => {
      setIsLoading(true);
  
      if (!user || window.location.pathname.startsWith("/networking")) {
        // User is signed out or on a networking page
        setCurrentUser(null);
        if (!window.location.pathname.startsWith("/networking")) navigate(`/`);
        setIsLoading(false);
        return;
      }
  
      // If user is signed in
      if (user.displayName) {
        syncUsers({
          uid: user.uid,
          displayName: user.displayName,
          email: user.email,
          conferenceCode: null,
          paymentTime: null,
          transactionId: null,
          ticketClass: null,
        } as User);
      }
  
      setEmailIsVerified(user.emailVerified);
  
      const idTokenResult = await user.getIdTokenResult();
      const accessLevel = idTokenResult.claims.accessLevel;
      const userData = await getUserData(user.uid);
  
      if (userData === null) {
        setIsLoading(false);
        return;
      }
  
      if (accessLevel === 1 || accessLevel === 2) {
        // Admin/Observer
        setCurrentUser({ ...userData, accessLevel });
        if (!window.location.pathname.startsWith(`/ADMIN`)) navigate('/ADMIN');
      } else {
        // Regular User
        setCurrentUser(userData);
        const { conferenceCode, preferences } = userData;
  
        if (conferenceCode) {
          const conferenceType = hylsCodes.includes(conferenceCode) ? 'hyls' : 'main';
          setConfType(conferenceType);
  
          if (preferences || conferenceType === 'hyls') {
            const confPath = `/${conferenceCode.slice(0, 7)}`;
            if (!window.location.pathname.startsWith(confPath)) navigate(confPath);
          }
        }
      }
  
      setIsLoading(false);
    };
  
    const unregisterAuthObserver = auth.onAuthStateChanged(handleAuthStateChange);
  
    return () => unregisterAuthObserver();
  }, [navigate]);
  

  const isSmallScreen = useMediaQuery('(max-width:800px)');

  return (
  <ThemeProvider theme={mdTheme}>
    <CssBaseline />
      <>
        {/* Don't show the main window if the window is loading (i.e. auth state is changing). Prevents flickering. */}
        {isLoading ? <Typography>Loading...</Typography> :
          <Routes>
            <Route path="/" element={<Home user={currentUser} setUser={setCurrentUser} emailVerified={emailIsVerified} setEmailVerified={setEmailIsVerified}/>} />
            {/* <Route path="/VSUMMIT/*" element={<SuccessPage user={currentUser} />} /> */}

            <Route path="/networking/:confCode" element={<NetworkingPage />} />

            {currentUser && <Route path="/ADMIN/" element={<AdminHome user={currentUser} />} />}
            {currentUser && <Route path="/ADMIN/users" element={<AdminUsers user={currentUser} />} />}
            {currentUser && <Route path="/ADMIN/payments" element={<AdminPayments />} />}
            {currentUser && <Route path="/ADMIN/:confCode/" element={<AdminConference isSmallScreen={isSmallScreen} user={currentUser} />} />}
            {currentUser && <Route path="/ADMIN/groups" element={<AdminGroups />} />}
            
            {currentUser && <Route path="/:confCode/" element={
              confType === 'hyls' ? 
              <HylsHome user={currentUser} confType={confType} isSmallScreen={isSmallScreen} /> :
              <ConfPage user={currentUser} isSmallScreen={isSmallScreen} />
              } />}
            
            {currentUser && confType && <Route path="/:confCode/settings" element={<SettingsPage user={currentUser} confType={confType} isSmallScreen={isSmallScreen} />} />}
            {currentUser && confType && <Route path="/:confCode/store" element={<Store user={currentUser} confType={confType} isSmallScreen={isSmallScreen} />} />}
            {currentUser && confType && <Route path="/:confCode/schedule" element={<Schedule user={currentUser} confType={confType} isSmallScreen={isSmallScreen} />} />}
            {currentUser && confType && <Route path="/:confCode/map" element={<MapPage user={currentUser} isSmallScreen={isSmallScreen} confType={confType}/>} />}
            {/* add default route that shows no routes found */}
            <Route path="*" element={<Typography>404: Not Found</Typography>} />
          </Routes>
        }
      </>
  </ThemeProvider>
  );
}
