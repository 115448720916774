import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { light_red } from "../../../utils/colors";
import { useAuth } from '../../../utils/firebase';
import CustomInput from "../../widgets/CustomInput";

interface PasswordFormProps {
  reauthenticateWithPassword: Function;
  updatePassword: Function;
}

interface EmailFormProps {
  updateOnVerifyEmail: Function;
}

interface PasswordFormData {
  password: string;
  newPassword: string;
  confirmPassword: string;
}

interface EmailFormData {
  password: string;
  newEmail: string;
}

const PasswordForm: React.FC<PasswordFormProps> = ({ reauthenticateWithPassword, updatePassword }) => {
  const { register, handleSubmit, formState: { errors }, setError } = useForm<PasswordFormData>();
  const [passwordChangeNote, setPasswordChangeNote] = useState<string | null>(null);

  const onSubmitPassword = async (data: PasswordFormData) => {
    try {
      if (data.newPassword !== data.confirmPassword) {
        setError("confirmPassword", { type: "manual", message: "New passwords do not match." });
        return;
      }
      await reauthenticateWithPassword(data.password);
      await updatePassword(data.newPassword);
      setPasswordChangeNote("Password successfully changed.");
    } catch (error) {
      console.error(error);
      setError("password", { type: "manual", message: "Incorrect password." });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmitPassword)}>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '30px',
        width: '100%',
      }}>
        <Typography
          sx={{
            color: light_red,
            fontWeight: 'bold',
            fontSize: '1.75rem',
          }}
        >
          Change Password
        </Typography>
        <CustomInput
          type="password"
          label="Password"
          id="password"
          required
          register={register("password")}
          errorText={errors.password && errors.password.message}
        />
        <CustomInput
          type="password"
          label="New Password"
          id="newPassword"
          required
          register={register("newPassword")}
          errorText={errors.newPassword && errors.newPassword.message}
        />
        <CustomInput
          label="Confirm Password"
          id="confirmPassword"
          type="password"
          required
          register={register("confirmPassword")}
          errorText={errors.confirmPassword && errors.confirmPassword.message}
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          sx={{
            marginTop: "10px",
            width: 'auto',
          }}
        >
          Change Password
        </Button>
        {passwordChangeNote && (
          <Typography>
            {passwordChangeNote}
          </Typography>
        )}
      </Box>
    </form>
  );
};

const EmailForm: React.FC<EmailFormProps> = ({ updateOnVerifyEmail }) => {
  const { register, handleSubmit, formState: { errors }, setError } = useForm<EmailFormData>();
  const [emailChangeNote, setEmailChangeNote] = useState<string | null>(null);

  const onSubmitEmail = async (data: EmailFormData) => {
    try {
      await updateOnVerifyEmail(data.password, data.newEmail);
      setEmailChangeNote("A verification email has been sent. Please check your inbox.");
    } catch (error: any) {
      if (error.message === "New email matches current email.") {
        setError("newEmail", { type: "manual", message: "New email matches current email." });
        console.log('New email matches current email.')
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmitEmail)}>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '30px',
        width: '100%',
      }}>
        <Typography
          sx={{
            color: light_red,
            fontWeight: 'bold',
            fontSize: '1.75rem',
          }}
        >
          Edit Email
        </Typography>
          <CustomInput
            label="New Email"
            id="newEmail"
            type="text"
            variant="standard"
            required
            register={register("newEmail")}
            errorText={errors.newEmail && errors.newEmail.message}
          />
          <CustomInput
            label="Password"
            id="password"
            type="password"
            variant="standard"
            required
            register={register("password")}
            errorText={errors.password && errors.password.message}
          />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          sx={{
            marginTop: "10px",
            width: 'auto',
          }}
        >
          Change Email
        </Button>
        {emailChangeNote && (
          <Typography>
            {emailChangeNote}
          </Typography>
        )}
      </Box>
    </form>
  );
};


interface ProfileTabProps {
  isSmallScreen: boolean;
}

const ProfileTab = ({isSmallScreen}: ProfileTabProps) => {
  const { reauthenticateWithPassword, updatePassword, updateOnVerifyEmail } = useAuth();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: isSmallScreen ? 'column' : 'row',
        alignItems: 'flex-start',
        gap: '40px',
        padding: isSmallScreen ? '0px' : '16px',
      }}
    >
      <PasswordForm
        reauthenticateWithPassword={reauthenticateWithPassword}
        updatePassword={updatePassword}
      />
      <EmailForm
        updateOnVerifyEmail={updateOnVerifyEmail}
      />
    </Box>
  );
};

export default ProfileTab;

