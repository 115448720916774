import React from "react";
import { Product } from "../../../utils/types";
import { Box, Button, IconButton, Tooltip, Typography } from "@mui/material";
import { dark_red } from "../../../utils/colors";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

interface ProductWidgetProps {
    product: Product;
    setQuantity: (product: Product, quantity: number) => void;
    quantity: number;
}

const ProductWidget: React.FC<ProductWidgetProps> = ({ product, setQuantity, quantity }) => {
    return (
        <Box sx={{
            boxShadow: 3,
            gap: '15px',
            width: '250px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            p: '20px',
        }}>
            <img
                src={product.imageUrl}
                alt={product.name}
                style={{
                    height: '200px',
                    width: '200px',
                    objectFit: 'cover',
                }}
            />
            <Typography variant="body1" sx={{ marginBottom: '5px', alignItems: 'center', display: 'flex', gap: '5px'}}
                >{product.name} {product.description && <Tooltip title={product.description}><InfoOutlinedIcon fontSize="small" /></Tooltip>}
            </Typography>
            {/* {product.description && <Typography variant="body2" sx={{ marginBottom: '5px' }}>{product.description}</Typography>} */}
            <Typography variant="body1" sx={{ fontWeight: 'bold', marginBottom: '5px' }}>${product.price}</Typography>
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                minHeight: '41px',
            }}>
                <Button onClick={() => {
                    if(quantity===0){
                        setQuantity(product, quantity + 1)
                    }
                }} sx={{
                    backgroundColor: dark_red,
                    color: 'white',
                    fontSize: '.75rem',
                    height: '35px',
                    '&:hover': {
                        backgroundColor: dark_red,
                    }
                }}>Add to Cart</Button>
                {(quantity > 0) &&
                    <Typography sx={{
                        border: '1px solid',
                        borderColor: '#d3d3d3',
                        borderRadius: '10px',
                        height: '37px',
                        display: 'flex',
                        alignItems: 'center',
                        p: '5px',
                    }}
                        component="span">
                        <IconButton onClick={() => setQuantity(product, quantity - 1)}><RemoveIcon /></IconButton>{quantity}<IconButton onClick={() => setQuantity(product, quantity + 1)}><AddIcon /></IconButton>
                    </Typography>
                }
            </Box>
        </Box>
    );
}

export default ProductWidget;
