import SearchIcon from '@mui/icons-material/Search';
import { Box, InputAdornment, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TextField, Typography } from "@mui/material";
import React, { useState } from 'react';

interface TableWidgetProps {
    rows: any[][];
    headers: string[];
    search?: boolean;
    tabs?: boolean;
    sx?: any;
    searchProps?: any;
}

export default function TableWidget({ rows, headers, search = false, tabs=true, sx=null, searchProps=null }: TableWidgetProps) {
    const [currentPage, setCurrentPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(25);
    const [searchQuery, setSearchQuery] = useState<string>('');

    const handleChangePage = (_: unknown, newPage: number) => {
        setCurrentPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setItemsPerPage(parseInt(event.target.value, 10));
        setCurrentPage(0); // Reset to the first page when changing the number of items per page
    };

    const filteredRows = rows.filter(row =>
        row.some(cell => cell.toString().toLowerCase().includes(searchQuery.toLowerCase()))
    );

    const paginatedRows = filteredRows.slice(currentPage * itemsPerPage, currentPage * itemsPerPage + itemsPerPage);

    return (
        <Box>
            {search && (
                <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                    <Typography variant="body2">Search for user</Typography>
                    <TextField
                        variant="outlined"
                        size="small"
                        placeholder='Search'
                        value={searchQuery}
                        sx={{
                            width: '40%',
                            ...searchProps
                        }}
                        onChange={(e) => {
                            setSearchQuery(e.target.value);
                            setCurrentPage(0); // Reset to the first page when a new search is made
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
            )}
            <Table sx={{ border: '1px solid #ddd', borderRadius: '8px', marginTop: '16px', ...sx }}>
                <TableHead>
                    <TableRow sx={{ backgroundColor: '#f5f5f5', color: '#333', paddingY: '8px', height: '30px' }}>
                        {headers.map((header, index) => (
                            <TableCell key={index} sx={{textAlign: 'left'}}>{header}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {paginatedRows.map((row, i) => (
                        <TableRow key={i} sx={{ height: '30px' }}>
                            {row.map((cell, j) => (
                                <TableCell key={j} sx={{ padding: '8px', textAlign: 'left' }}>{cell}</TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            {tabs && <TablePagination
                rowsPerPageOptions={[25, 50, 100]} // Set your desired options
                component="div"
                count={filteredRows.length}
                rowsPerPage={itemsPerPage}
                page={currentPage}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />}
        </Box>
    );
}
