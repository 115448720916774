import InfoIcon from '@mui/icons-material/ErrorOutline';
import { Box, Button, FormControl, MenuItem, TextField, Tooltip, Typography } from "@mui/material";
import { ICountry, countries } from "countries-list";
import MuiPhoneNumber from "mui-phone-number";
import React, { useEffect, useState} from "react";
import { useForm } from "react-hook-form";
import { light_red } from "../../utils/colors";
import { updateUserPrefs } from "../../utils/mutations/users";
import { User } from "../../utils/types";

// Define FormData interface
interface FormData {
  preferredName: string;
  preferredEmail?: string;
  phoneNumber?: string;
  birthdate: string;
  gender: string;
  nationality: string;
  hometown: string;
  linkedIn?: string;
  occupation: string;
  dietaryRestriction: string;
  foodAllergies?: string;
  accommodations?: string;
}

interface PreferencesWidgetProps {
  user: User;
  onSubmit?: (data: FormData) => void;

}

const PreferencesWidget = ({ user, onSubmit }: PreferencesWidgetProps) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: user.preferences ? JSON.parse(user.preferences) : {},
  });

  const [phoneNumber, setPhoneNumber] = useState<string | undefined>(undefined);

  useEffect(() => {
    // use `reset` to reset the form with the defaultValues
    if(!user.preferences) {
      return;
    }
    const data = JSON.parse(user.preferences);
    if(data) {
      reset(data);
    }
    setPhoneNumber(data.phoneNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.preferences]);

  const onSubmitPreferences = async (data: FormData) => {
    // attach the phone number to the data object
    data.phoneNumber = phoneNumber;
    await updateUserPrefs(user.uid, JSON.stringify(data));
    // Call the onSubmit function if it exists
    onSubmit?.(data);
  };

  const occupationsList = ["Healthcare", "Education/Student", "Entrepreneurship", "Finance", "Consulting", "Technology/IT", "Marketing/Advertising", "Nonprofit/NGO", "Government/Public Sector", "Law/Legal Services", "Real Estate", "Manufacturing", "Retail/E-commerce", "Energy", "Transportation/Logistics", "Hospitality/Tourism", "Arts/Entertainment", "Media/Journalism", "Biotechnology/Pharmaceuticals", "Engineering", "Construction", "Environmental/Sustainability", "Sports/Recreation", "Automotive", "Telecommunications"];

  return (
    <Box sx={{
      overflowY: 'scroll',
      padding: '5px 20px',
      maxHeight: '75vh',
    }}>
      <form onSubmit={handleSubmit(onSubmitPreferences)}>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          color: 'black',
          gap: '5px',
        }}>
          {/* Personal Section */}
          <Typography sx={{ color: light_red, fontWeight: "bold", fontSize: "1.75rem" }}>
            Personal
          </Typography>
          <FormControl fullWidth>
            <Typography component="span" sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
              Preferred Name *
              <Tooltip title="This name will appear on your delegate badge and certificate of participation exactly as it is typed, including capitalization and formatting. It's important to ensure that the information is typed correctly, as it may be difficult to change later." sx={{ marginLeft: '5px' }}>
                <InfoIcon sx={{ fontSize: 'medium', marginLeft: '5px' }} />
              </Tooltip>
            </Typography>
            <TextField
              {...register("preferredName", { required: true })}
              error={!!errors.preferredName}
              helperText={errors.preferredName && "Preferred Name is required"}
              fullWidth
              variant="standard"
            />
          </FormControl>
          <FormControl fullWidth>
            <Typography sx={{ marginBottom: 1 }}>Preferred Email</Typography>
            <TextField variant="standard" fullWidth multiline {...register("preferredEmail")} />
          </FormControl>
          <FormControl fullWidth>
            <Typography sx={{ marginBottom: 1 }}>Phone Number</Typography>
            <MuiPhoneNumber
              value={phoneNumber}
              defaultCountry={"us"}
              onChange={(value: any) => {
                setPhoneNumber(value);
              }}
            />
          </FormControl>
          <FormControl fullWidth>
            <Typography sx={{ marginBottom: 1 }}>Birthdate *</Typography>
            <TextField
              type="date"
              {...register("birthdate", { required: true })}
              error={!!errors.birthdate}
              helperText={errors.birthdate && "Birthdate is required"}
              fullWidth
              variant="standard"
            />
          </FormControl>
          <FormControl fullWidth>
            <Typography sx={{ marginBottom: 1 }}>Gender *</Typography>
            <TextField
              select
              {...register("gender", { required: true })}
              error={!!errors.gender}
              helperText={errors.gender && "Gender is required"}
              fullWidth
              variant="standard"
              defaultValue={user.preferences && JSON.parse(user.preferences).gender}
            >
              <MenuItem value="">Select a gender</MenuItem>
              {["Male", "Female", "Nonbinary"].map((gender: string) => (
                <MenuItem key={gender} value={gender}>
                  {gender}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
          <FormControl fullWidth>
            <Typography sx={{ marginBottom: 1 }}>Primary Nationality *</Typography>
            <TextField
              select
              {...register("nationality", { required: true })}
              error={!!errors.nationality}
              helperText={errors.nationality && "Nationality is required"}
              fullWidth
              variant="standard"
              defaultValue={user.preferences && JSON.parse(user.preferences).nationality}
            >
              <MenuItem value="">Select a country</MenuItem>
              {Object.values(countries).sort((a: ICountry, b: ICountry) => {
                return a.name.localeCompare(b.name);
              }).map((country) => (
                <MenuItem key={country.name} value={country.name}>
                  {country.name}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
          <FormControl fullWidth>
            <Typography sx={{ marginBottom: 1 }}>Hometown *</Typography>
            <TextField 
            variant="standard" 
            fullWidth 
            {...register("hometown", {required: true})} 
            error={!!errors.hometown}
            helperText={errors.hometown && "Hometown is required"}
               />
          </FormControl>
          <FormControl fullWidth>
            <Typography sx={{ marginBottom: 1 }}>LinkedIn</Typography>
            <TextField variant="standard" fullWidth multiline {...register("linkedIn")} />
          </FormControl>
          <FormControl fullWidth>
            <Typography sx={{ marginBottom: 1 }}>Occupation *</Typography>
            <TextField
              select
              {...register("occupation", { required: true })}
              error={!!errors.occupation}
              helperText={errors.occupation && "Occupation is required"}
              fullWidth
              variant="standard"
              defaultValue={user.preferences && JSON.parse(user.preferences).occupation}
            >
              <MenuItem value="">Select an occupation</MenuItem>
              {occupationsList.sort().map((occupation: string) => (
                <MenuItem key={occupation} value={occupation}>
                  {occupation}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
          {/* Dietary Information Section */}
          <Typography sx={{ color: light_red, fontWeight: "bold", fontSize: "1.75rem" }}>
            Dietary
          </Typography>
          <FormControl fullWidth>
            <Typography sx={{ marginBottom: 1 }}>Dietary Restriction *</Typography>
            <TextField
              select
              {...register("dietaryRestriction", { required: true })}
              defaultValue={user.preferences && JSON.parse(user.preferences).dietaryRestriction}
              error={errors.dietaryRestriction ? true : false}
              fullWidth
              variant="standard"
            >
              {["None", "Vegetarian", "Vegan", "Halal", "Gluten-free"].map((restriction) => (
                <MenuItem key={restriction} value={restriction.toLowerCase()}>
                  {restriction}
                </MenuItem>
              ))}
            </TextField>
            {errors.dietaryRestriction && (
              <Typography variant="body2" color="error">
                Dietary Restriction is required
              </Typography>
            )}
          </FormControl>
          <FormControl fullWidth>
            <Typography sx={{ marginBottom: 1 }}>Do you have any food allergies?</Typography>
            <TextField variant="standard" fullWidth {...register("foodAllergies")} />
          </FormControl>

          {/* Additional Section */}
          <Typography sx={{ color: light_red, fontWeight: "bold", fontSize: "1.75rem" }}>
            Additional
          </Typography>
          <FormControl fullWidth>
            <Typography sx={{ marginBottom: 1 }}>Physical Accommodations</Typography>
            <TextField variant="standard" fullWidth multiline {...register("accommodations")} />
          </FormControl>
          {/* Submit Button */}
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              sx={{ marginTop: "20px", width: "200px" }}
            >
              Save Preferences
            </Button>
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default PreferencesWidget;