import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, IconButton, TextField, Typography } from "@mui/material";
import React, { useState } from "react";

interface createGroupsProps {
    groups: { name: string; capacity: number }[];
    setGroups: React.Dispatch<React.SetStateAction<{ name: string; capacity: number }[]>>;
    setPage: (page: number) => void;
}

export default function CreateGroups({ groups, setGroups, setPage }: createGroupsProps) {
    const [error, setError] = useState<{ index: number, message: string } | null>(null);

    const addGroup = () => {
        setGroups(prevGroups => [...prevGroups, { name: '', capacity: 0 }]);
    };

    const handleGroupNameChange = (index: number, newName: string) => {
        setGroups(prevGroups => {
            const updatedGroups = [...prevGroups];
            updatedGroups[index].name = newName;
            return updatedGroups;
        });
        if (error && error.index === index) setError(null);
    };

    const handleGroupCapacityChange = (index: number, newCapacity: number) => {
        setGroups(prevGroups => {
            const updatedGroups = [...prevGroups];
            updatedGroups[index].capacity = newCapacity;
            return updatedGroups;
        });
    };

    const verifyGroups = () => {
        const emptyGroupIndex = groups.findIndex(group => group.name.trim() === '');
        const zeroCapacityGroupIndex = groups.findIndex(group => group.capacity <= 0);
        
        if (emptyGroupIndex !== -1) {
            setError({ index: emptyGroupIndex, message: 'Please fill out all group names' });
        } else if (zeroCapacityGroupIndex !== -1) {
            setError({ index: zeroCapacityGroupIndex, message: 'Capacity must be greater than 0' });
        } else {
            setPage(1);
        }
    }
    

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '10px',
            marginTop: '20px',
        }}>
            <Typography>Please input the groups</Typography>
            {groups.map((group, index) => (
            <Box key={index}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '10px',
                }}>
                    <TextField
                        label="Group Name"
                        variant="standard"
                        value={group.name}
                        onChange={(e) => handleGroupNameChange(index, e.target.value)}
                        error={!!(error && error.index === index)}
                        sx={{ marginBottom: '10px' }}
                    />
                    <TextField
                        type="number"
                        label="Capacity"
                        variant="standard"
                        value={group.capacity}
                        error={!!(error && error.index === index)}
                        onChange={(e) => handleGroupCapacityChange(index, parseInt(e.target.value))}
                        sx={{ marginBottom: '10px' }}
                    />
                    <IconButton onClick={() => setGroups(prevGroups => prevGroups.filter((_, i) => i !== index))} size="small">
                        <CloseIcon />
                    </IconButton>
                </Box>
                {error && error.index === index && <Typography variant='body2' color="error">{error.message}</Typography>}
                </Box>
            ))}
            <Button onClick={addGroup}>Add Group</Button>
            <Button variant="contained" onClick={verifyGroups}>Next</Button>
        </Box>
    );
}
