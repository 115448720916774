import { doc, getDoc, setDoc, collection, onSnapshot } from 'firebase/firestore';
import { db } from '../firebase';

import { Payment } from '../types';

// mutation to add payment info to new payments collection 
export async function addPaymentInfo(payment : Payment) : Promise<void> {
   try{
      await setDoc(doc(db, 'payments', payment.transactionId), {
         type: payment.type,
         amount: payment.amount,
         uid: payment.uid,
         dateCreated: payment.dateCreated.valueOf(),
         item: payment.item,
         customerCode: payment.customerCode,
      });
   } catch (error) {
      console.error(error);
   } 
} 

export async function getPaymentInfo(transactionId: string): Promise<Payment | null> {
   try {
        const paymentRef = doc(db, 'payments', transactionId);
        const paymentDoc = await getDoc(paymentRef);
        if (paymentDoc.exists()) {
            const payment: Payment = {
                type: paymentDoc.data().type ?? "N/A",
                amount: paymentDoc.data().amount,
                transactionId: transactionId,
                uid: paymentDoc.data().uid,
                dateCreated: new Date(paymentDoc.data().dateCreated),
                item: paymentDoc.data().item || paymentDoc.data().joinCode,
                customerCode: paymentDoc.data().customerCode ?? 'N/A',
            };
            return payment;
        }
       
       return null;
   } catch (error) {
       console.error(error);
       return null;
   }
}

// subscribe to payments collection
export function subscribeToPayments(callback: (payments: Payment[]) => void) {
   const paymentsRef = collection(db, 'payments');
   const unsubscribe = onSnapshot(paymentsRef, (snapshot) => {
      const payments: Payment[] = [];
      snapshot.forEach((doc) => {
         const payment: Payment = {
            type: doc.data().type ?? "N/A",
            amount: doc.data().amount,
            transactionId: doc.id,
            uid: doc.data().uid,
            dateCreated: new Date(doc.data().dateCreated),
            item: doc.data().item || doc.data().joinCode,
            customerCode: doc.data().customerCode ?? 'N/A',
         };
         payments.push(payment);
      });
      callback(payments);
   });
   return unsubscribe;
}