import { Close, People, Download } from '@mui/icons-material';
import { Dialog, DialogTitle, DialogContent, IconButton, Typography, Box, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Product, User } from '../../../../utils/types';
import { getPurchasers } from '../../../../utils/mutations/products';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import { dark_red } from '../../../../utils/colors';
import TableWidget from '../../../widgets/TableWidget';

interface PurchaserModalProps {
    confCode: string;
    product: Product;
}

const PurchaserModal: React.FC<PurchaserModalProps> = ({ confCode, product }) => {
    const [open, setOpen] = useState(false);

    const [purchasers, setPurchasers] = useState<{purchaser: User; quantity: number}[]>([]);

    useEffect(() => {
        const fetchPurchasers = async () => {
            if (product.id) {
                const _purchasers = await getPurchasers(product.id, confCode); 
                
                setPurchasers(_purchasers);

                sessionStorage.setItem(`purchasers_${product.id}`, JSON.stringify(_purchasers));
            }
        };

        const cached: {purchaser: User; quantity: number}[] = JSON.parse(sessionStorage.getItem(`purchasers_${product.id}`) ?? '[]');
        if (cached.length > 0) {
            setPurchasers(cached);
        } else {
            fetchPurchasers();
        }
    }, [product.id, confCode]);

    const handleClose = () => {
        setOpen(false);
    };

    const handleDownload = () => {
        const csv = [
            ['Name', 'Email', 'uid', 'Quantity'],
            ...purchasers.map(purchaser => [purchaser.purchaser.displayName, purchaser.purchaser.email, purchaser.purchaser.uid, purchaser.quantity]),
        ];

        const csvContent = 'data:text/csv;charset=utf-8,' + csv.map(e => e.join(',')).join('\n');
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', `${product.name}_purchasers.csv`);
        document.body.appendChild(link);
        link.click();
    }

    const rows = purchasers.map(purchaser => [
        purchaser.purchaser.displayName,
        purchaser.quantity,
    ]);

    const headers = ['Name', 'Quantity'];

    return (
        <>
            <IconButton onClick={() => setOpen(true)}><People /></IconButton>
            <Dialog open={open} onClose={handleClose} PaperProps={{
                    sx: {
                        minWidth: '50vw',
                        maxWidth: '100%',
                    }
                }}>
                <DialogTitle>
                    Purchasers ({purchasers.length ?? 0})
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}>
                    {purchasers.length > 0  ?   
                    <Box sx={{
                        minWidth: '40vw',
                        display: 'flex',
                        flexDirection: 'column',
                        }}>
                        <Button startIcon={<Download />} onClick={handleDownload} sx={{
                            alignSelf: 'flex-end',
                        }}>Download</Button>
                        <TableWidget headers={headers} rows={rows} tabs={true} sx={{
                            width: '100%',
                        }}/>
                    </Box> :
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}>
                        <SentimentVeryDissatisfiedIcon sx={{ fontSize: 100, color: dark_red }} />
                        <Typography>No purchasers</Typography>
                    </Box>
                    }
                </DialogContent>
            </Dialog>
        </>
    );
};

export default PurchaserModal;
