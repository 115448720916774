import { Box, Button } from "@mui/material";
import React from "react";
import TableWidget from "../../widgets/TableWidget";

interface Person{
    name: string;
    email: string;
    rankings: number[];
}

interface ImportRankingsProps {
    groups: { name: string; capacity: number }[];
    people: Person[];
    setPeople: (people: Person[]) => void;
    setPage: (page: number) => void;
}

export default function ImportRankings({ groups, people, setPeople, setPage }: ImportRankingsProps) {
    const parseCSV = (text: string) => {
        const lines = text.trim().split('\n');
        const rows = lines.slice(1).map(line => (
            line.split(',').map(value => value.trim())
        ));
        return rows;
    };

    const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const text = e.target?.result as string;
                const data = parseCSV(text);
                const formattedData: any[] = data.map((row: string[]) => ({
                    name: row[0],
                    email: row[1],
                    rankings: row.slice(2),
                }));

                // Check some constraints
                if(formattedData[0].rankings.length !== groups.length) {
                    alert('Number of rankings should be equal to the number of groups');
                    return;
                }
                if(groups.map(group => group.capacity).reduce((partialSum, a) => partialSum + a, 0) < formattedData.length) {
                    alert('Total capacity of groups should be greater than or equal to the number of people');
                    return;
                }
                if(formattedData.length === 0) {
                    alert('No data found in the file');
                    return;
                }
                if(formattedData.length > 1000) { 
                    alert('Number of people should be less than 1000');
                    return;
                }
                if(formattedData.some(person => person.rankings.some((ranking: any) => isNaN(Number(ranking))))){
                    alert('Rankings should be numbers');
                    return;
                }

                // If the constraints are met, set the people state
                setPeople(formattedData);
            };
            reader.readAsText(file);
        }
    };

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '10px',
            marginTop: '20px',
        }}>
            <Button variant="contained" component="label">
                Import CSV
                <input type="file" accept=".csv" hidden onChange={handleFileUpload} />
            </Button>
            <Button 
                variant="text"
                onClick={() => {
                    if(people.length > 0){
                        if(window.confirm("Are you sure you want to go back? All uploaded data will be lost.")){
                            setPage(0);
                        }
                    } else {
                        setPage(0);
                    }
            }}>Back</Button>
            {people.length > 0 && (
                <Box> 
                    <Button onClick={() => setPage(2)}>Next</Button>
                    <TableWidget headers={["name", "email", ...groups.map(g => g.name)]} rows={people.map((person) => ([person.name,person.email, ...person.rankings]))} />
                </Box>
            )}
        </Box>
    );
}
