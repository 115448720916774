import { Box, Button, Typography } from "@mui/material";
import React from 'react';
import { useForm } from "react-hook-form";
import { dark_red } from '../../utils/colors';
import { auth } from '../../utils/firebase';
import { syncUsers } from '../../utils/mutations/users';
import type { User } from '../../utils/types';
import CustomInput from "../widgets/CustomInput";



// ! I need to figure out how I want the email verification to look.

interface SignUpProps {
  setIsSignUp: (isSignUp: boolean) => void;
  setUser: (user: User) => void;
}

interface FormData {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
}

export default function SignUp({ setIsSignUp, setUser }: SignUpProps) {
  const { register, handleSubmit, formState: { errors }, setError } = useForm<FormData>();

  const handleSignUp = async (formData: FormData) => {
    if (formData.password !== formData.confirmPassword) {
        setError("confirmPassword", { type: "manual", message: "Passwords do not match" });
        return;
    }

    const name : string = `${formData.firstName} ${formData.lastName}`;

    try {
        const userCredential = await auth.createUserWithEmailAndPassword(formData.email, formData.password);
        if (!userCredential.user) throw new Error('User creation failed');

        // Run these operations in parallel since they don't depend on each other
        await Promise.all([
            userCredential.user.updateProfile({ displayName: name }),
            userCredential.user.sendEmailVerification(),
        ]);

        const user: User = {
            displayName: name,
            email: formData.email,
            uid: userCredential.user.uid,
            conferenceCode: null,
            transactionId: null,
            ticketClass: null,
            paymentTime: null,
            preferences: null,
            events: null,
        };

        // Wait for user document creation before updating UI
        await syncUsers(user);
        setUser(user);
    } catch (error) {
        console.error('Error during sign up:', error);
        // Show appropriate error message to user
        throw error;
    }
  };

  return (
    <Box style={{
      width: '300px'
    }}>
      <Typography variant="h4" sx={{
        color: 'black',
        fontWeight: 'bold',
        marginBottom: '10px',
      }}>Sign Up</Typography>

      <form onSubmit={handleSubmit(handleSignUp)}>
        <Box sx={{
          display: 'flex',
          gap: '10px',
          flexDirection: 'row',
        
        }}>
        <CustomInput
          id="first-name"
          label="First Name"
          type="text"
          variant="standard"
          register={register("firstName", { required: true })}
          error={!!errors.firstName}
          helperText={errors.firstName && "First name is required"}
          color='secondary'
          width={50}
        />
        <CustomInput
          id="last-name"
          label="Last Name"
          type="text"
          variant="standard"
          register={register("lastName", { required: true })}
          error={!!errors.lastName}
          helperText={errors.lastName && "Last name is required"}
          color='secondary'
          width={50}
        />
        </Box>
        <CustomInput
          id="email"
          label="Email"
          type="text"
          variant="standard"
          register={register("email", { required: true })}
          error={!!errors.email}
          helperText={errors.email && errors.email.message}
          color='secondary'
        />
        <CustomInput
          type="password"
          label="Password"
          id="password"
          variant="standard"
          register={register("password", { required: true, minLength: 8 })}
          error={!!errors.password}
          helperText={errors.password && "Password must be at least 8 characters"}
          color='secondary'
        />
        <CustomInput
          type="password"
          label="Confirm Password"
          id="confirmPassword"
          variant="standard"
          register={register("confirmPassword", { required: true })}
          error={!!errors.confirmPassword}
          helperText={errors.confirmPassword && errors.confirmPassword.message}
          color='secondary'
        />
        <Button variant="contained" type="submit" sx={{
          width: '300px',
          backgroundColor: dark_red,
          color: 'white',
          borderRadius: '3px',
        }}>
          Sign Up
        </Button>
      </form>
      <Typography sx={{ margin: '8px 0', display: 'flex', alignItems: 'center', fontSize: 'inherit' }}>
        Already signed up?
        <Button
          onClick={() => setIsSignUp(false)}
          sx={{
            textDecoration: 'none',
            color: 'dark_red',
            fontWeight: 'bold',
            textTransform: 'none',
            fontSize: 'inherit',
            p: '0px',
            marginLeft: '5px',
          }}
        >
          Sign in
        </Button>
      </Typography>
    </Box>
  );
}
