import { Unsubscribe, doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { User } from '../types';

// TODO: WRAP THE MUTATIONS IN TRANSACTIONS SO THAT THEY ADD OR FAIL TOGETHER
// TODO: MOVE THE ADD/REMOVE USER FUNCTIONS TO THE CONFERENCE MUTATIONS FILE (AND RENAME THE ADD FOR CONSISTENCY)

// Synchronize users table upon login
export async function syncUsers(user: User): Promise<void> {
   try {
      const userRef = doc(db, 'users', user.uid);

      // If the user does not have an existing entry in the users table, then create it
      const userDoc = await getDoc(userRef);
      
      // since we are never using setDoc when the user already exists, we won't ever overwrite defined values with null
      if (!userDoc.exists()) {
         await setDoc(userRef, {
            email: user.email,
            displayName: user.displayName,
         });
      }
   } catch (error) {
      console.error(error);
      throw error; // Rethrow the error to indicate that the synchronization failed
   }
}

// mutation to get user data
export async function getUserData(uid: string): Promise<User | null> {
   try {
      const userRef = doc(db, 'users', uid);
      const userDoc = await getDoc(userRef);

      if (userDoc.exists()) {
         const user: User = {
            uid: userDoc.id,
            displayName: userDoc.data().displayName,
            email: userDoc.data().email,
            conferenceCode: userDoc.data().conferenceCode ?? null,
            ticketClass: userDoc.data().ticketClass ?? null,
            transactionId: userDoc.data().transactionId ?? null,
            paymentTime: userDoc.data().paymentTime ? new Date(userDoc.data().paymentTime.seconds * 1000) : null,
            preferences: userDoc.data().preferences ?? null,
            events: userDoc.data().events
         } as User;
         return user;
      } else {
         return null;
      }
   } catch (error) {
      console.error(error);
      // Handle the error or rethrow it if needed
      throw error;
   }
}

//  create a snapshot to listen for all users in the `users` collection with cofereceCode == confCode
export function subscribeToUsersInConf(confCode : string, callback : (data: User[]) => void) : Unsubscribe {
    const usersRef = db.collection('users');
    const unsubscribe = usersRef.where('conferenceCode', '==', confCode).onSnapshot((snapshot : any) => {
       const users = snapshot.docs.map((doc : any) => ({
          uid: doc.id,
          displayName: doc.data().displayName,
          email: doc.data().email,
          conferenceCode: doc.data().conferenceCode,
          ticketClass: doc.data().ticketClass,
          transactionId: doc.data().transactionId || doc.data().paymentID || null,
          paymentTime: new Date(doc.data()?.paymentTime?.seconds * 1000) ?? null,
          preferences: doc.data().preferences,
          events: doc.data().events,
       } as User));

       callback(users);
    });
    return unsubscribe;
 }
 
 export function subscribeToUsers(callback : (data: User[]) => void) {
    const usersRef = db.collection('users');
    const unsubscribe = usersRef.onSnapshot((snapshot : any) => {
       const users = snapshot.docs.map((doc : any) => ({
          uid: doc.id,
          displayName: doc.data().displayName,
          email: doc.data().email,
          conferenceCode: doc.data().conferenceCode,
          ticketClass: doc.data().ticketClass,
          transactionId: doc.data().transactionId || doc.data().orderID || null,
          preferences: doc.data().preferences,
          events: doc.data().events,
       } as User));
       callback(users);
    });
    return unsubscribe;
}

// mutation to remove user from the conference, the payment document is saved for record keeping
export async function removeUser(uid : string) : Promise <boolean> {
    try {
        const userRef = doc(db, 'users', uid);
        // remove this user from the conference
        await updateDoc(userRef, {
            conferenceCode: null,
            ticketClass: null,
            transactionId: null,
            paymentTime: null,
        });
        return true;
    } catch (error) {
       console.error(error);
       return false;
    }
}

// mutation to update user's preferences
export async function updateUserPrefs(uid: string, preferences: string) {
   try {
      const userRef = doc(db, 'users', uid);
      await updateDoc(userRef, {
         preferences: preferences,
      });
   } catch (error) {
      console.error(error);
      throw error;
   }
}

// function to get the products that the user has purchased, which are stored as a map of id and quantity
export async function getUserProducts(uid: string): Promise<string[]> {
   try {
      const userRef = doc(db, 'users', uid);
      const userDoc = await getDoc(userRef);

      if (userDoc.exists()) {
         const products = userDoc.data()?.products;
         return products;
      } else {
         return [];
      }
   } catch (error) {
      console.error(error);
      throw error;
   }
}

// function to update the user's conference code
export function updateUserConfCode(uid: string, newConfCode: string, confCode: string | null): Promise<void> {
   const userRef = doc(db, 'users', uid);
   return updateDoc(userRef, {
      conferenceCode: newConfCode,
      ticketClass: confCode === 'F' ? 'F' : 'M',
   });
}
