import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Confetti from 'react-dom-confetti';
import { useParams } from 'react-router-dom';
import { getUserData } from '../../../utils/mutations/users';
import type { User } from "../../../utils/types";
import Footer from "../menuBars/Footer";
import MenuBar from "../menuBars/MenuBar";

interface HylsHomeProps {
  user: User;
  confType: 'main' | 'hyls';
  isSmallScreen: boolean;
}

export default function HylsHome({ user, confType, isSmallScreen }: HylsHomeProps): JSX.Element {
  const [loading, setLoading] = useState<boolean>(true);
  const [paymentID, setPaymentID] = useState<string | null>(null);
  const [confetti, setConfetti] = useState<boolean>(false);

  const { confCode } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!user) throw new Error("User is null");
        const userData = await getUserData(user.uid);
        setPaymentID(userData?.transactionId?.[0] ?? '');
      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [user]);

  useEffect(() => {
    setConfetti(true);
  }, []);

  const confettiConfig = {
    angle: 90,
    spread: 360,
    startVelocity: 30,
    elementCount: 200,
    dragFriction: 0.10,
    duration: 3000,
    stagger: 3,
    width: "10px",
    height: "10px",
    colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"],
  };

  return (
    loading ? (
      <Typography>Loading...</Typography>
    ) : !isSmallScreen ? (
      <>
        {confCode && <MenuBar user={user} confType={confType} isSmallScreen={isSmallScreen} />}
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', overflow: 'hidden', marginTop: '64px' }}>
          <Confetti active={confetti} config={confettiConfig} />
          
          <Box sx={{
            backgroundImage: 'url(/art/banner.png)',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            width: '100%',
            height: '33vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: 'white',
          }}>
            <Typography variant="h2" sx={{ mb: 2, fontWeight: "bold", textAlign: "center" }}>
              Congratulations, {user?.displayName?.split(' ')[0] || "Guest"}!
            </Typography>
          </Box>

          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '50px',
          }}>
            <Box sx={{ maxWidth: '45%', padding: '130px 0px' }}>
              <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                Welcome to Harvard Youth Leadership Summit's Conference!
              </Typography>
              <Typography variant="h6">
                We are so excited to see you and believe you have the potential to make a meaningful impact on the world! You were selected from a pool of thousands of qualified applicants, demonstrating the exceptional qualities and abilities that set you apart.
              </Typography>
              {paymentID && paymentID !== "N/A" && (
                <>
                <Typography variant="h6" sx={{ textAlign: "center", mt: 2, fontWeight: "bold" }}>
                  Your Payment ID: {paymentID}
                </Typography>
                <Typography variant="h6" sx={{ textAlign: "center", mt: 2, fontWeight: "bold" }}>
                  Please save it!
                </Typography>
                </>
              )}
              <Typography variant="h6" sx={{ textAlign: "center", mt: 2, fontWeight: "bold" }}>
                We will be sending out all further details via email shortly. Thank you!
              </Typography>
            </Box>
            <iframe title="welcome video" style={{ width: '40vw', height: '45vh' }} src="https://www.youtube.com/embed/QQ01szcHJ88?si=o5bfL847nI63SVqP?autoplay=1&mute=0"></iframe>
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '100%', background: 'white' }}>
            <img src="/art/hyls_logo.png" alt="Theme" style={{ width: '100%' }} />
          </Box>
        </Box>
        <Footer />
      </>
    ) : (
      <>
        {confCode && <MenuBar user={user} confType={confType} isSmallScreen={isSmallScreen} />}
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', overflow: 'hidden', marginTop: '64px'}}>
          <Confetti active={confetti} config={confettiConfig} />
          <Box sx={{
            backgroundImage: 'url(/art/banner.png)',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            width: '100%',
            height: '20vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: 'white',
          }}>
            <Typography variant="h4" sx={{ fontWeight: "bold", textAlign: "center" }}>
              Congratulations, {user?.displayName?.split(' ')[0] || "Guest"}!
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 4, padding: '20px' }}>
            <Typography variant="h5" sx={{ fontWeight: "bold", textAlign: 'center' }}>
              Welcome to the Harvard Youth Leadership Summit!
            </Typography>
            <Typography variant="h6">
              We are so excited to see you and believe you have the potential to make a meaningful impact on the world! You were selected from a pool of thousands of qualified applicants, demonstrating the exceptional qualities and abilities that set you apart.
            </Typography>
            <iframe title="welcome video" style={{ width: '80vw', height: 'auto'}} src="https://www.youtube.com/embed/QQ01szcHJ88?si=o5bfL847nI63SVqP?autoplay=1&mute=0">
            </iframe>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '100%', background: 'white' }}>
            <img src="/art/hyls_logo.png" alt="Theme" style={{ width: '100%' }} />
          </Box>
        </Box>
        <Footer />
      </>
    )
  );
}