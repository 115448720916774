import { Box, Typography, Button } from "@mui/material";
import React, { useState, useEffect } from 'react';
import EmailIcon from '@mui/icons-material/Email';

import { auth } from '../../utils/firebase';

interface VerifyEmailProps {
    setEmailVerified: (emailVerified: boolean) => void;
}

export default function VerifyEmail({ setEmailVerified }: VerifyEmailProps) {

    const [timer, setTimer] = useState<number>(30);

    useEffect(() => {
        // Add code here to verify the user's email
        const user = auth.currentUser;

        const timerInterval = setInterval(() => {
            if (timer > 0) {
                setTimer(prevTimer => prevTimer - 1);
            }
        }, 1000);

        const emailVerificationInterval = setInterval(() => {
            user?.reload();
            if (user?.emailVerified) {
                setEmailVerified(true);
            }
        }, 1000);

        return () => {
            clearInterval(timerInterval);
            clearInterval(emailVerificationInterval);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setEmailVerified]);

    const handleResendEmail = () => {
        setTimer(30);
        const user = auth.currentUser;
        user?.sendEmailVerification();
    };

    return (
        <Box style={{
            width: '300px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        }}>
            <Typography variant="h4" sx={{
                color: 'black',
                fontWeight: 'bold',
                marginBottom: '10px',
            }}>Verify Email</Typography>
            {/* large email icon */}
            <EmailIcon sx={{ fontSize: 100, color: 'black' }} />
            <Typography variant="body1" sx={{ color: 'black' }}>
                Please verify your email by clicking the link sent to the address given to continue.
            </Typography>

            {(timer > 0) ? (
                <Typography variant="body1" sx={{ color: 'black' }}>Please wait {timer} seconds before requesting a new email.</Typography>
            ) : (
                <Button
                    onClick={handleResendEmail}
                    sx={{
                        width: '100%',
                        color: 'white',
                        backgroundColor: 'black',
                        '&:hover': {
                            backgroundColor: 'black',
                        }
                    }}>Resend Email</Button>

            )}

            <Button
                onClick={() => auth.signOut()}
                sx={{
                    width: '100%',
                    color: 'white',
                }}>Log Out</Button>
        </Box>
    );
}
