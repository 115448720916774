import {
  AppBar,
  Box,
  Button,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AccountMenu from './AccountMenu';
import { dark_red } from '../../../utils/colors';
import type { User } from '../../../utils/types';

interface MenuBarProps {
  user: User;
  isSmallScreen: boolean;
  confType: 'main' | 'hyls';
}

const menuItems = {
  main: {
    menu: ['store', 'schedule', 'map'],
    account: ['settings', 'qr', 'logout', 'support'],
  },
  hyls: {
    menu: ['schedule'],
    account: ['qr', 'logout', 'support'],
  },
};

export default function MenuBar({ user, isSmallScreen, confType }: MenuBarProps): JSX.Element {
  const navigate = useNavigate();
  const { conferenceCode } = user;
  const [mobileMenuAnchor, setMobileMenuAnchor] = useState<null | HTMLElement>(null);

  const handleMobileMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMenuAnchor(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMenuAnchor(null);
  };

  const currentMenuItems = menuItems[confType];

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          height: '64px',
          zIndex: 1,
          backgroundColor: 'white',
        }}
        elevation={0}
      >
        <Toolbar sx={{ width: '100%', color: 'black' }}>
          {isSmallScreen ? (
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <IconButton color="inherit" onClick={handleMobileMenuClick}>
                <MenuIcon />
              </IconButton>
              <Button
                onClick={() => navigate(`/${conferenceCode}`)}
                sx={{
                  width: '300px',
                  '&:hover': { backgroundColor: 'transparent' },
                }}
                disableFocusRipple
                disableRipple
                disableTouchRipple
              >
                <div
                  style={{
                    width: '100%',
                    height: '64px',
                    background: `url("/art/hpair-logo.png") no-repeat center/contain`,
                  }}
                />
              </Button>
              {conferenceCode && <AccountMenu 
              user={user} 
              confCode={conferenceCode} 
              accountMenuItems={currentMenuItems['account']} />}
            </Box>
          ) : (
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Button
                onClick={() => navigate(`/${conferenceCode}`)}
                sx={{
                  width: '300px',
                  '&:hover': { backgroundColor: 'transparent' },
                }}
                disableFocusRipple
                disableRipple
                disableTouchRipple
              >
                <div
                  style={{
                    width: '100%',
                    height: '64px',
                    background: `url("/art/HPAIR Logo Banner (Black).png") no-repeat center/contain`,
                  }}
                />
              </Button>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
                {currentMenuItems.menu.map((menuItem) => (
                  <Button
                    key={menuItem}
                    sx={{
                      color: window.location.pathname.includes(menuItem) ? dark_red : 'inherit',
                      '&:hover': {
                        backgroundColor: 'transparent',
                      },
                    }}
                    variant="text"
                    onClick={() => navigate(`/${conferenceCode}/${menuItem}`)}
                    disableTouchRipple
                    disableFocusRipple
                    disableRipple
                  >
                    {menuItem.charAt(0).toUpperCase() + menuItem.slice(1)}
                  </Button>
                ))}
                {conferenceCode && <AccountMenu
                  user={user}
                  confCode={conferenceCode}
                  accountMenuItems={currentMenuItems['account']}
                  sx={{
                    color: window.location.pathname.includes('settings') ? dark_red : 'inherit',
                  }}
                />}
              </Box>
            </Box>
          )}
        </Toolbar>

        {/* Mobile menu */}
        {mobileMenuAnchor && (
          <Menu
            anchorEl={mobileMenuAnchor}
            open={Boolean(mobileMenuAnchor)}
            onClose={handleMobileMenuClose}
          >
            {currentMenuItems.menu.map((menuItem) => (
              <MenuItem
                key={menuItem}
                onClick={() => {
                  navigate(`/${conferenceCode}/${menuItem}`);
                  handleMobileMenuClose();
                }}
              >
                {menuItem.charAt(0).toUpperCase() + menuItem.slice(1)}
              </MenuItem>
            ))}
          </Menu>
        )}
      </AppBar>
    </>
  );
}
