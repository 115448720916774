import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { Box, Typography, Tooltip } from "@mui/material";
import React from 'react'; 
import { dark_red, light_red } from '../../../utils/colors';
import type { Day, User, Location, Event } from '../../../utils/types';

interface DayPanelProps {
    index: number;
    day: Day;
    user: User;
    handleLocationClick: (location: Location) => void;
}

export default function DayPanel({ index, day, user, handleLocationClick }: DayPanelProps) {

    const formatTitle = (str: string) => {
        const maxLength = 40; // max characters limit
    
        if (str.length > maxLength) {
            // Find the last space within the maxLength
            const truncated = str.slice(0, maxLength);
            const lastSpaceIndex = truncated.lastIndexOf(' ');
    
            // Use the part of the string up to the last space if one exists, otherwise use the whole truncated string
            const displayStr = lastSpaceIndex !== -1 ? truncated.slice(0, lastSpaceIndex) : truncated;
    
            return (
                <Tooltip title={str} placement="top">
                    <Typography sx={{ fontStyle: 'italic' }} variant="body2">{displayStr}...</Typography>
                </Tooltip>
            );
        } else {
            return <Typography variant="body2" sx={{ fontStyle: 'italic' }}>{str}</Typography>;
        }
    };
    

    return (
        <Box
            role="tabpanel"
            key={index}
            sx={{
                p: "30px",
                m: '10px',
                border: '1px solid lightgrey',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                '@media (max-width: 600px)': {
                    p: '15px',
                },
            }}
        >
            <>
                <CalendarTodayIcon sx={{ fontSize: 'large', color: dark_red, textAlign: 'center', alignSelf: 'center', marginBottom: '20px' }} />
                {/* day title Day 1: 8/20 */}
                <Typography variant="h5" sx={{ textAlign: 'center', fontWeight: 'bold' }}> Day {index + 1}: {day.date}</Typography>
                {/* events */}
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '20px',
                    width: '100%',
                    '@media (max-width: 600px)': {
                        gridTemplateColumns: '1fr',
                    },
                }}>
                    {day.events
                        .filter((event: Event) => event.assignedIDs.includes(user.uid))
                        .map((event: Event, eventIndex: number) => (
                            <Box
                                key={eventIndex}
                                sx={{
                                    padding: '15px',
                                    borderRadius: '5px',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                {/* Time */}
                                <Typography variant="body1" sx={{ marginRight: '2rem', fontWeight: 'bold' }}>{event.start} - {event.end} </Typography>

                                {/* Event details container */}
                                <Box sx={{ marginLeft: '15px', flex: '1' }}>
                                    {/* Event details */}
                                    <Typography variant="h6" sx={{ color: light_red, fontWeight: 'bold' }}>{event.name} </Typography>
                                    {/* Location */}
                                    {
                                    event.link ? 
                                    <a href={event.link} target="_blank" rel="noreferrer" style={{ textDecoration: 'none', color: 'black' }}><Typography variant="body2" sx={{ marginBottom: '4px', cursor: 'pointer' }}>Zoom Link</Typography></a> :
                                    <Typography variant="body2" sx={{ marginBottom: '4px', cursor: 'pointer' }} onClick={() => { if (event.location) { handleLocationClick(event.location) } }}>{event.location?.name}</Typography>
                                    }
                                    <Typography variant="body1">{event.speaker}</Typography>
                                    {event.speakerTitle && formatTitle(event.speakerTitle)}
                                </Box>
                            </Box>
                        ))}
                </Box>
                {
                    day.events.filter(event => event.assignedIDs.includes(user.uid)).length === 0 && (
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: '10px',
                            padding: '20px',
                            margin: '20px',
                        }}>
                            <Typography variant="h6" sx={{ color: 'black' }}>No events available. Please check back in later.</Typography>
                        </Box>
                    )
                }
            </>
    </Box>
 )
}
