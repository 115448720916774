import {
  Typography,
  Box,
  Button,
  Card,
  CardContent,
  CardActions,
  CircularProgress,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import FileDownloadIcon from '@mui/icons-material/FileDownload';

interface RedactedUser {
  name: string;
  email: string;
  phoneNumber: string | null;
  linkedIn: string | null;
  nationality: string | null;
  occupation: string | null;
}

export default function NetworkingPage(): JSX.Element {
  const [searchParams] = useSearchParams();
  const [userData, setUserData] = useState<RedactedUser | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const uuid = searchParams.keys().next().value;

      if (uuid && uuid.trim() !== "") {
        try {
          const response = await axios.post("https://getuserdata-2t5cbdn56q-uc.a.run.app", {
            uid: uuid,
          });

          if (response.data) {
            setUserData(response.data);
          } else {
            console.error("No data returned from the server.");
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      } else {
        console.warn("Invalid or missing UUID in search parameters.");
      }
      setLoading(false);
    };

    fetchData();
  }, [searchParams]);

  const downloadVCard = () => {
    if (!userData) {
      alert("No user data available to create contact card.");
      console.error("No user data available to create vCard.");
      return;
    }
  
    // Construct the vCard dynamically
    let vCardData = `BEGIN:VCARD\nVERSION:3.0\nFN:${userData.name}\n`;
  
    if (userData.email) {
      vCardData += `EMAIL:${userData.email}\n`;
    }
    if (userData.phoneNumber) {
      vCardData += `TEL:${userData.phoneNumber}\n`;
    }
    if (userData.linkedIn) {
      vCardData += `URL:${userData.linkedIn}\n`;
    }
    if (userData.nationality) {
      vCardData += `NOTE:Nationality: ${userData.nationality}\n`;
    }
    if (userData.occupation) {
      vCardData += `NOTE:Occupation: ${userData.occupation}\n`;
    }
  
    vCardData += `END:VCARD`;
  
    // Create and download the vCard
    const blob = new Blob([vCardData], { type: "text/vcard" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `${userData.name.replace(/\s+/g, "_")}.vcf`;
    link.click();
  };
  

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (!userData) {
    return (
      <Box display="flex" flexDirection="column" alignItems="center" mt={4}>
        <Typography variant="h5" color="error">
          No user data found.
        </Typography>
      </Box>
    );
  }

  const formatLinkedIn = (url: string): string => {
    if (url.startsWith("http://") || url.startsWith("https://")) {
      return url;
    }

    return `https://${url}`;
  }

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundImage: "url(/art/dunster-background.jpg)",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Card
        elevation={3}
        sx={{
          width: { xs: "100%", sm: "90%", md: "400px" },
          padding: 2,
          maxWidth: "500px",
          backdropFilter: "blur(10px) saturate(180%)",
          backgroundColor: "rgba(255, 255, 255, .75)",
          borderRadius: "12px",
          boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
        }}
      >
        <CardContent>
          <Typography variant="h5" gutterBottom>
          {userData.name}
          </Typography>
          <Typography variant="body1">
            <strong>Email:</strong> <a style={{color: 'black', textDecoration: 'none'}} href={`mailto:${userData.email}`}>{userData.email}</a>
          </Typography>
          {userData.phoneNumber && (
            <Typography variant="body1">
              <strong>Phone:</strong> <a style={{color: 'black', textDecoration: 'none'}} rel="noopener noreferrer" target="_blank" href={`tel:${userData.phoneNumber}`}>{userData.phoneNumber}</a>
            </Typography>
          )}
          {userData.linkedIn && (
            <Typography variant="body1">
              <strong>LinkedIn:</strong>{' '}
              <a
                style={{ color: 'black', textDecoration: 'none' }}
                href={formatLinkedIn(userData.linkedIn)}
                target="_blank"
                rel="noopener noreferrer"
              >
                {userData.linkedIn}
              </a>
            </Typography>
          )}

          {userData.nationality && <Typography variant="body1">
            <strong>Nationality:</strong> {userData.nationality}
          </Typography>}
          {userData.occupation && <Typography variant="body1">
            <strong>Occupation:</strong> {userData.occupation}
          </Typography>}
        </CardContent>
        <CardActions>
          <Button
            variant="outlined"
            color="primary"
            onClick={downloadVCard}
            fullWidth
            sx={{
              padding: { xs: "10px", sm: "12px" },
            }}
            startIcon={<FileDownloadIcon />}
          >
            Download Contact
          </Button>
        </CardActions>
      </Card>
    </Box>
  );
}
