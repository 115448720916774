import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import QRCode from 'qrcode.react';
import React, { useEffect } from 'react';
import { User } from '../../../utils/types';
// import {isIOS} from 'react-device-detect';

interface QRCodeModalProps {
  user: User;
  open: boolean;
  onClose: () => void;
}

const QRCodeModal: React.FC<QRCodeModalProps> = ({ open, user, onClose }) => {
    // const handleAddToWallet = async () => {
    //     const response = await fetch('https://createpass-2t5cbdn56q-uc.a.run.app', {
    //       method: 'POST',
    //       headers: {
    //         'Content-Type': 'application/json',
    //       },
    //       body: JSON.stringify({ uid: user.uid, name: user.displayName }),
    //     });
    
    //     if (response.ok) {
    //         const blob = await response.blob();
    //         const url = window.URL.createObjectURL(blob);
    //         const a = document.createElement('a');
    //         a.href = url;
    //         a.download = 'pass.pkpass';
    //         document.body.appendChild(a);
    //         a.click();
    //         a.remove();
    //     } else {
    //        console.log(response)
    //     }
    // };

    const [qrCodeValue, setQrCodeValue] = React.useState<string | null>(null);
    useEffect(() => {
        setQrCodeValue(`https://my.hpair.org/networking/${user.conferenceCode}?${user.uid}`);
    }, [user.uid, user.conferenceCode]);

    return(
    <>
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '50px',
                marginTop: '20px',
            }}>
                {qrCodeValue && <QRCode value={qrCodeValue} size={256} />}
                <Typography variant="h4" sx={{ textAlign: 'center', fontWeight: 'bold', marginTop: '20px' }}>
                    {user.displayName}
                </Typography>
                {/* {isIOS && <Button onClick={handleAddToWallet}>
                    <img src="art/Apple_Wallet.svg" alt="Save to Apple Wallet" />
                </Button>} */}
            </DialogContent>
        </Dialog>
    </>
)};

export default QRCodeModal;
