// product.ts

import { Unsubscribe } from 'firebase/firestore';
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { db } from '../firebase';
import { doc, updateDoc, collection, setDoc, deleteDoc } from 'firebase/firestore';
import { uploadBytes } from "firebase/storage";
import { Product, User } from '../types';

const storage = getStorage();

async function getProductImageUrl(confCode: string, productId: string): Promise<string> {
    const imageRef = ref(storage, `products/${confCode}/${productId}.png`);
    try {
        const url = await getDownloadURL(imageRef);
        return url;
    } catch (error) {
        console.error("Error fetching image URL:", error);
        return "";
    }
}

export function subscribeToProducts(confCode: string, callback: (data: Product[]) => void, filterActive: boolean = false): Unsubscribe {
    // Start building the Firestore query
    let productsRef;

    // Apply the where condition only if filterActive is true
    if (filterActive) {
        productsRef = db.collection("conferences").doc(confCode).collection("products").where("isActive", "==", true);
    } else {
        productsRef = db.collection("conferences").doc(confCode).collection("products");
    }

    const unsubscribe = productsRef.onSnapshot(async (snapshot) => {
        const productsPromises = snapshot.docs.map(async (doc) => {
            const data = doc.data();
            return {
                id: doc.id,
                name: data.name,
                price: data.price,
                description: data.description,
                isActive: data.isActive ?? true, // Default to true if not present
                imageUrl: await getProductImageUrl(confCode, doc.id),
            } as Product;
        });
        const products = await Promise.all(productsPromises);
        callback(products);
    });
    return unsubscribe;
}

export async function createProduct(confCode: string, product: Product): Promise<string> {
    try {
        const productsCollection = collection(db, `conferences/${confCode}/products`);
        const newProductRef = doc(productsCollection);
        await setDoc(newProductRef, {
            name: product.name,
            price: product.price,
            isActive: true, // Newly created products are active by default
            description: product.description,
        });
        return newProductRef.id;
    } catch (error) {
        console.error("Error creating product:", error);
        throw error;
    }
}

export async function updateProduct(confCode: string, productId: string, product: Product): Promise<void> {
    try {
        const productRef = doc(db, `conferences/${confCode}/products/${productId}`);
        if (productRef) {
            await updateDoc(productRef, {
                name: product.name,
                price: product.price,
                isActive: product.isActive, // Update isActive flag
                description: product.description,
            });
        }
    } catch (error) {
        console.error("Error updating product:", error);
    }
}

export async function uploadImage(confCode: string, productId: string, imageFile: File): Promise<void> {
    try {
        const storageRef = ref(storage, `products/${confCode}/${productId}.png`);
        await uploadBytes(storageRef, imageFile);
    } catch (error) {
        console.error("Error uploading image:", error);
    }
}

export async function deleteProduct(confCode: string, productId: string): Promise<void> {
    try {
        const productRef = doc(db, `conferences/${confCode}/products/${productId}`);
        await deleteDoc(productRef);
    } catch (error) {
        console.error("Error deleting product:", error);
    }
}

export async function getPurchasers(productId: string, confCode: string): Promise<any[]> {
    try {
        const purchasersRef = db.collection(`users`)
            .where(`products.${productId}`, ">", 0);
        const purchasersSnapshot = await purchasersRef.get();

        const purchasers = purchasersSnapshot.docs.map((doc) => {
            const purchaser: User = {
                uid: doc.id,
                displayName: doc.data().displayName,
                email: doc.data().email,
            } as User;

            return {
                quantity: doc.data().products[productId],
                purchaser: purchaser,
                confCode: doc.data().conferenceCode,
            };
        }).filter((purchaser) => purchaser.confCode === confCode);

        return purchasers;

    } catch (error) {
        console.error("Error fetching purchasers:", error);
        return [];
    }
}
