import { Box, MenuItem, Select, Tab, Tabs, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { subscribeToConference } from '../../../utils/mutations/conferences';
import { subscribeToProducts } from '../../../utils/mutations/products';
import { subscribeToSchedule } from '../../../utils/mutations/schedule';
import { subscribeToUsersInConf } from '../../../utils/mutations/users';
import { Conference, Day, Product, User } from '../../../utils/types';
import Footer from "../adminMenuBar/AdminFooter";
import MenuBar from '../adminMenuBar/AdminMenuBar';
import DetailsTab from './Details/DetailsTab';
import AdminQrScanner from './QrScanner/AdminQrScanner';
import RegistrantTable from './Registrants/RegistrantTab';
import ScheduleTab from './Schedule/ScheduleTab';
import ProductTable from './Store/ProductTab';

interface AdminConferenceProps {
  user: User;
  isSmallScreen: boolean;
}

export default function AdminConference({ isSmallScreen, user }: AdminConferenceProps) {
  const { confCode } = useParams<{ confCode: string }>();

  const [confData, setConfData] = useState<Conference | null>(null);
  const [registrants, setRegistrants] = useState<User[]>([]);
  const [selectedTab, setSelectedTab] = useState<number>(user.accessLevel === 1 ? 1 : 4);

  const [products, setProducts] = useState<Product[]>([]);
  const [localDays, setLocalDays] = useState<Day[]>([]);

  useEffect(() => {
    if (!confCode) {
      console.log('No conference code provided');
      return;
    }

    const unsubscribeSchedule = subscribeToSchedule(confCode, (data: Day[]): void => {
      if (data) {
          const sortedDays = data.sort((a: Day, b: Day) => {
              if (!a.date || !b.date) return 0;

              const aDateParts = a.date.split("/");
              const bDateParts = b.date.split("/");
              const aMonth = parseInt(aDateParts[0]);
              const bMonth = parseInt(bDateParts[0]);
              const aDay = parseInt(aDateParts[1]);
              const bDay = parseInt(bDateParts[1]);

              // Compare months first
              if (aMonth !== bMonth) {
                  return aMonth - bMonth;
              }

              // If months are the same, compare days
              return aDay - bDay;
          });

          const localDays: Day[] = sortedDays.map((day: Day) => ({
              ...day,
              isEditing: false,
              isDeleted: false,
              deletedEvents: []
          }));

          setLocalDays(localDays);
        } else {
            console.log(`Days in conference with code ${confCode} not found`);
        }
    });    

    const unsubscribeConference = subscribeToConference(
      confCode,
      (data: Conference | null): void => {
        if (data) {
          setConfData(data);
        } else {
          console.log(`Conference with code ${confCode} not found`);
        }
      },
    );

    const unsubscribeUsersInConf = subscribeToUsersInConf(
      confCode,
      (data: User[] | null): void => {
        if (data) {
          setRegistrants(data);
        } else {
          console.log(`Users in conference with code ${confCode} not found`);
        }
      },
    );

    return () => {
      unsubscribeConference();
      unsubscribeUsersInConf();
      unsubscribeSchedule();
    };
  }, [confCode]);

  useEffect(() => {
    // If the user is an OBSERVER don't load the products
    if(user.accessLevel === 2){
      return;
    }

    if (!confCode) {
      console.log('No conference code provided');
      return;
    }

    const unsubscribeProducts = subscribeToProducts(confCode, (data: Product[]): void => {
      if (data) {
        setProducts(data);
      } else {
        console.log(`Products in conference with code ${confCode} not found`);
      }
    });

    return () => {
      unsubscribeProducts();
    };
  });

  const handleTabChange = (_: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const handleDropdownChange = (event: any) => {
    setSelectedTab(event.target.value as number);
  };

  const tabLabels = ['Details', 'Registrants', 'Store', 'Schedule', 'Scanner'];

  return (
    <>
      <MenuBar />
      <Box sx={{ marginTop: '64px', padding: '10px', minHeight: 'calc(100vh - 64px - 110px)' }}>
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="h4" sx={{ marginBottom: '20px' }}>
            {confData?.conferenceName} ({confCode}){' '}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', alignContent: "center", width: '100%' }}>
          {(user.accessLevel === 1) && (
            isSmallScreen ? (
              <Select
                value={selectedTab}
                onChange={handleDropdownChange}
                variant="outlined"
              >
                {tabLabels.map((label, index) => (
                  <MenuItem key={label} value={index}>
                    {label}
                  </MenuItem>
                ))}
              </Select>) : (
              <Tabs value={selectedTab} onChange={handleTabChange}>
                {tabLabels.map((label) => (
                  <Tab key={label} label={label} />
                ))}
              </Tabs>
            )
          )
          }
        </Box>
        {user.accessLevel === 1 && (
          <>
            {selectedTab === 0 && confData && <DetailsTab conference={confData} registrants={registrants} />}
            {selectedTab === 1 && <RegistrantTable registrants={registrants} />}
            {selectedTab === 2 && confCode && <ProductTable products={products} confCode={confCode} />}
            {selectedTab === 3 && confCode && (
              <ScheduleTab
                confCode={confCode}
                users={registrants}
                daysLocal={localDays}
                setDaysLocal={setLocalDays}
              />
            )}
            {selectedTab === 4 && confCode && <AdminQrScanner confCode={confCode} />}
          </>
        )}

        {
          user.accessLevel === 2 && confCode && (
            <AdminQrScanner confCode={confCode} />
          )
        }
      </Box>
      <Footer />
    </>
  );
}
