import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from 'react';
import { dark_red } from '../../../utils/colors';
import { subscribeToProducts } from '../../../utils/mutations/products';
import { Payment, Product, User } from '../../../utils/types';
import Footer from '../menuBars/Footer';
import MenuBar from '../menuBars/MenuBar';
import Checkout from './Checkout';
import PaymentSuccess from './PaymentSuccess';
import ProductWidget from './ProductWidget';

interface StoreProps {
    user: User;
    confType: 'main' | 'hyls';
    isSmallScreen: boolean;
}

const Store = ({ user, confType, isSmallScreen }: StoreProps) => {
    const [products, setProducts] = useState<Product[]>(JSON.parse(sessionStorage.getItem('products') || '[]'));
    // 0: Product view ; 1: Checkout page ; 2: Payment success page
    const [storePage, setStorePage] = useState<number>(0); 
    const [cart, setCart] = useState<{[key: string]: number}>(JSON.parse(sessionStorage.getItem('cart') || '{}'));

    const [transactionId, setTransactionId] = useState<string>('');

    const onSuccess = (payment: Payment): void => {
        setCart({});
        sessionStorage.removeItem('cart');
        setTransactionId(payment.transactionId);
        setStorePage(2);
    };

    useEffect(() => {
        sessionStorage.setItem('cart', JSON.stringify(cart));
        if(Object.keys(cart).length === 0 && storePage === 1) {
            setStorePage(0);
        }
    }, [cart, storePage]);

    const setQuantity = (product: Product, quantity: number) => {
        if(!product.id) return;
        if (quantity < 0 || quantity > 3) return;
        
        const productId: string = product.id;
        if (quantity === 0) {
            setCart(prevCart => {
                const { [productId]: removedProduct, ...updatedCart } = prevCart;
                return updatedCart;
            });
        } else if (quantity > 0 && quantity <= 3) {
            setCart(prevCart => ({
                ...prevCart,
                [productId]: quantity
            }));
        }
    };


    useEffect(() => {
        if (products.length === 0) {
            if (!user?.conferenceCode) return;
            const unsubscribe = subscribeToProducts(user.conferenceCode, (data) => {
                setProducts(data);
                // sessionStorage.setItem('products', JSON.stringify(data));
            }, true);

            return () => {
                unsubscribe();
            };
        }
    }, [products, user.conferenceCode]);

    const sum : number = Object.values(cart)?.length > 0 ? Object.values(cart).reduce((a, b) => a + b, 0) : 0;

    return (
        <Box sx={{
            overflow: 'hidden',
            position: 'relative',
        }}>
            <MenuBar user={user} confType={confType} isSmallScreen={isSmallScreen} />
            <Box sx={{
                marginTop: '64px',
                padding: '20px 0px',
                minHeight: 'calc(100vh - 64px - 130px)'
            }}>
                <Box style={{ position: 'relative', textAlign: 'center' }}>
                    <Typography variant="h3" sx={{ fontWeight: 'bold', color: dark_red }}>Store</Typography>
                    {!isSmallScreen ? (<Button
                        sx={{ color: 'black', position: 'absolute', top: '50%', right: '20px', transform: 'translateY(-50%)' }}
                        onClick={()=>setStorePage(1)}
                    >
                        <ShoppingCartIcon style={{ verticalAlign: 'middle' }} /> Checkout ({sum})
                    </Button>) : (
                    <Button
                        sx={{ color: 'black' }}
                        onClick={()=>setStorePage(1)}
                    >
                        <ShoppingCartIcon style={{ verticalAlign: 'middle' }} /> Checkout ({sum})
                    </Button>)}
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                        width: 'calc(100% - 50px)',
                        marginLeft: '25px',
                        padding: '40px',
                        gap: '50px',
                    }}
                >
                    {products.map((product, i) => (
                        <ProductWidget 
                            product={product} 
                            setQuantity={setQuantity}
                            key={i} 
                            quantity={product.id && cart[product.id] ? cart[product.id] : 0} 
                        />
                    ))}
                </Box>
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        right: storePage === 1 ? 0 : '-100%',
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'white',
                        zIndex: 2,
                        transition: 'right 0.3s ease-out',
                    }}
                >
                    {storePage === 1 &&
                        <Checkout cart={cart} products={products} onSuccess={onSuccess} onClose={()=>setStorePage(0)} user={user} setQuantity={setQuantity} />
                    }
                </Box>

                {storePage === 2 && <Box sx={{
                    position: 'absolute',
                    top: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'white',
                    zIndex: 2,
                }}>
                    <PaymentSuccess transactionId={transactionId} />
                </Box>}
            </Box>
            <Footer />
        </Box>
    );
};

export default Store;
