import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { subscribeToPayments } from '../../../utils/mutations/payments';
import { Payment } from '../../../utils/types';
import TableWidget from '../../widgets/TableWidget';
import Footer from '../adminMenuBar/AdminFooter';
import MenuBar from '../adminMenuBar/AdminMenuBar';


export default function AdminPayments() {
    const [payments, setPayments] = useState<Payment[]>([]);

    useEffect(() => {
      const unsubscribe = subscribeToPayments((data : Payment[]) => {
        setPayments(data.sort((a, b) => b.dateCreated.getTime() - a.dateCreated.getTime()));
      });

      // Cleanup the listener when the component unmounts
      return () => unsubscribe();
    }, []);

    const rows = payments.map(payment => [
      payment.type || '-',
      payment.item || '-',
      payment.amount ? `$${payment.amount}` : '-',
      payment.customerCode || '-',
      payment.dateCreated ? new Date(payment.dateCreated).toLocaleDateString() : '-',
      payment.transactionId || '-',
    ]);
    
    const headers = ['Type', 'Item', 'Amount', 'Customer Code', 'Date', 'Transaction ID'];

    return (
      <Box sx={{
        minHeight: 'calc(100vh - 64px - 110px)'
      }}>
        <MenuBar />
        <Box sx={{ marginTop: '64px', p: '10px 30px' }}>
          <TableWidget rows={rows} headers={headers} search={true} />
        </Box>
        <Footer />
      </Box>
    );
}
