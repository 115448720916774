import Logout from '@mui/icons-material/Logout';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { Box, IconButton, ListItemIcon, Menu, MenuItem, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import QRCodeModal from '../QrCodeModal/QrCodeModal';

import { auth } from '../../../utils/firebase';
import type { User } from '../../../utils/types';
import SupportModal from '../supportModal/SupportModal';
import { Settings } from '@mui/icons-material';

interface AccountMenuProps {
  user: User;
  confCode: string;
  accountMenuItems: string[];
  sx?: Object;
}

export default function AccountMenu({ user, confCode, accountMenuItems, sx}: AccountMenuProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [qrOpen, setQrOpen] = useState(false);

  const [supportOpen, setSupportOpen] = useState(false);

  const open = Boolean(anchorEl);

  const navigate = useNavigate();

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <SupportModal open={supportOpen} onClose={() => setSupportOpen(false)} />
      {user && <QRCodeModal user={user} open={qrOpen} onClose={() => setQrOpen(false)} />}
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <IconButton
          onClick={handleOpen}
          size="small"
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          sx={sx}
          disableRipple
          disableTouchRipple
        >
          <SettingsOutlinedIcon />
        </IconButton>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {user && <MenuItem onClick={() => setQrOpen(true)}>
            <Typography
                variant="body1"
                sx={{ 
                    color: 'black', 
                    fontWeight: 'bold',  
                    textAlign: 'center',
                }}
            >
                {user?.displayName?.length > 20 ? `${user.displayName.slice(0, 20)}...` : `${user?.displayName}`}
            </Typography>
        </MenuItem>}
        {accountMenuItems.includes("support") && (
        <MenuItem onClick={() => setSupportOpen(true)}>
          <ListItemIcon><SupportAgentIcon fontSize="small" /></ListItemIcon>
          Support
        </MenuItem>
        )}
        {accountMenuItems.includes('settings') && (
          <MenuItem onClick={() => navigate(`/${confCode}/settings`)}>
            <ListItemIcon><Settings fontSize="small" /></ListItemIcon>
            Settings
          </MenuItem>
          )
        }
        <MenuItem onClick={() => auth.signOut()}>
          <ListItemIcon><Logout fontSize="small" /></ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </>
  );
}
