import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Box, Typography } from '@mui/material';
import React from 'react';
import { dark_red } from '../../../utils/colors';

export default function Footer() {
    const socialMediaLinks = [
        {
            label: "LinkedIn",
            icon: <LinkedInIcon sx={{ fontSize: 20 }} />,
            link: "https://www.linkedin.com/company/harvard-project-for-asian-and-international-relations-hpair-"
        },
        {
            label: "Instagram",
            icon: <InstagramIcon sx={{ fontSize: 20 }} />,
            link: "https://www.instagram.com/officialhpair/?hl=en"
        },
        {
            label: "Facebook",
            icon: <FacebookIcon sx={{ fontSize: 20 }} />,
            link: "https://www.facebook.com/official.hpair/"
        }
    ];

    return (
        <Box sx={{ 
            bgcolor: dark_red, 
            color: 'white', 
            textAlign: 'center', 
            height: '130px',
            display: 'flex', 
            gap: '5px',
            justifyContent: 'center', 
            alignItems: 'center', 
            flexDirection: 'column',
            width: '100%'
        }}>
            <Box sx={{ display: 'flex', gap: '14px' }}>
                {socialMediaLinks.map((item, index) => (
                    <Box key={index} sx={{ margin: '0', paddingX: '8px', cursor: 'pointer' }} onClick={() => window.open(item.link, '_blank')}>
                        {item.icon}
                    </Box>
                ))}
            </Box>
            <a href='mailto:help@hpair.org' style={{
                textDecoration: 'none',
                color: 'white'
            }}>help@hpair.org</a> 
            <Typography component="span">Copyright © 2024 HPAIR, Inc. - All Rights Reserved.</Typography>
        </Box>
    );
}