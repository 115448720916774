import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import { Backdrop, Box, Button, Checkbox, CircularProgress, TextField, Typography } from "@mui/material";
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { dark_red } from '../../utils/colors';
import { auth, refreshUser } from '../../utils/firebase';
import type { User } from '../../utils/types';
import PaymentWidget from '../widgets/PaymentWidget';
import PreferencesWidget from "../widgets/PreferencesWidget";

/**
 * This function handles the credentialed user
 * @param user - The user object
 * @param accessLevel - The access level of the user (1 for admin, 2 for observer)
*/
const handleCredentialedUser = async (user: User, accessLevel: 1 | 2) => {
  const url = 'https://setcredential-2t5cbdn56q-uc.a.run.app';
  const data = {
    uid: user.uid,
    accessLevel: accessLevel,
  };

  try {
    await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });

    await refreshUser();
    window.location.reload();
  } catch (error) {
    console.error('Error:', error);
  }
};

interface JoinConfProps {
  user: User;
}

export default function JoinConf({ user }: JoinConfProps): JSX.Element {
  // Show dark backdrop when loading
  const [showBackdrop, setShowBackdrop] = useState<boolean>(false);

  // 0: Join Form, 1: Payment, 2: Preferences
  const [pageState, setPageState] = useState<number>(0);

  const [price, setPrice] = useState<number>(0);
  
  // Code segments for the join code
  const [codeSegments, setCodeSegments] = useState<string[]>(Array(3).fill(""));

  // State of the checkboxes for the main conferences
  const [plusExec, setPlusExec] = useState<boolean>(false);
  const [isVerified, setIsVerified] = useState<boolean>(false);

  // Errors for the join code and verification
  const [errors, setErrors] = useState<{ joinCode?: string; isVerified?: string }>({});

  useEffect(() => {
    if (user.conferenceCode) {
      setPageState(2);
    }
  }, [user.conferenceCode]);

  const handleValidation = async () => {
    setErrors({});
    setShowBackdrop(true);

    if (codeSegments[0] === "OBSERVE") {
      await handleCredentialedUser(user, 2);
      return;
    }

    if (codeSegments[0] === "ADMIN") {
      await handleCredentialedUser(user, 1);
      return;
    }

    if (codeSegments.some(segment => segment === "")) {
      setErrors({ joinCode: "Invalid join code" });
      setShowBackdrop(false);
      return;
    }

    const joinCode = codeSegments.join("-");

    if (codeSegments[0] === "HCONF25" && !isVerified) {
      setErrors({ isVerified: "You must accept the Terms and Conditions." });
      setShowBackdrop(false);
      return;
    }

    try {
      const res = await axios.post('https://verifycode-2t5cbdn56q-uc.a.run.app', {
        code: joinCode,
        email: user.email,
      });

      const { isValid, price } = res.data;

      if (isValid) {
        if (codeSegments[1] === "F" && !plusExec) {
          await axios.post("https://completepayment-2t5cbdn56q-uc.a.run.app", {
            uid: user.uid,
            paymentId: "N/A",
            cart: [{ type: "ticket", item: joinCode, quantity: 1 }]
          });

          window.location.reload();
        } else {
          setPrice(price);
          setPageState(1);
        }
      } else {
        setErrors({ joinCode: "Invalid join code" });
      }
    } catch (error) {
      console.error("Error during validation", error);
    } finally {
      setShowBackdrop(false);
    }
  };

  const handleSegmentChange = (e: React.ChangeEvent<HTMLInputElement>, index: number, isUpper: boolean = false) => {
    let value = e.target.value;
    if (isUpper) {
      value = value.toUpperCase();
    }
    if (/^[A-Za-z0-9]*$/.test(value)) {
      const newSegments = [...codeSegments];
      newSegments[index] = value;
      setCodeSegments(newSegments);
    }
  };

  const renderJoinForm = () => (
    <Box sx={{ width: "300px" }}>
      <Typography variant="h4" sx={{ color: 'black', fontWeight: 'bold', marginBottom: '25px' }}>
        Join Conference
      </Typography>
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <TextField
            id="code-segment-0"
            value={codeSegments[0]}
            onChange={(e: any) => handleSegmentChange(e, 0, true)}
            variant="outlined"
            inputProps={{
              maxLength: 7,
              style: {
                textAlign: "center",
                padding: '15px 0px',
              },
            }}
            sx={{
              width: "7em",
            }}
            onKeyDown={(e: React.KeyboardEvent) => {
              if (codeSegments[0].length === 7 && /^[a-zA-Z0-9]$/.test(e.key)) {
                (document.getElementById("code-segment-1") as HTMLInputElement)?.focus();
              }
            }}
          />
          <HorizontalRuleIcon sx={{ color: 'black' }} />
          <TextField
            id="code-segment-1"
            value={codeSegments[1]}
            onChange={(e: any) => handleSegmentChange(e, 1, true)}
            variant="outlined"
            inputProps={{
              maxLength: 1,
              style: {
                textAlign: "center",
                padding: '15px 0px',
              },
            }}
            sx={{
              width: "2em",
            }}
            onKeyDown={(e: React.KeyboardEvent) => {
              if (e.key === "Backspace" && codeSegments[1] === "") {
                (document.getElementById("code-segment-0") as HTMLInputElement)?.focus();
              } else if (codeSegments[1].length === 1 && /^[a-zA-Z0-9]$/.test(e.key)) {
                (document.getElementById("code-segment-2") as HTMLInputElement)?.focus();
              }
            }}
          />
          <HorizontalRuleIcon sx={{ color: 'black' }} />
          <TextField
            id="code-segment-2"
            value={codeSegments[2]}
            onChange={(e: any) => handleSegmentChange(e, 2, false)}
            variant="outlined"
            inputProps={{
              maxLength: 6,
              style: {
                textAlign: "center",
                padding: '15px 0px',
              },
            }}
            sx={{
              width: "6em",
            }}
            onKeyDown={(e: React.KeyboardEvent) => {
              if (e.key === "Backspace" && codeSegments[2] === "") {
                (document.getElementById("code-segment-1") as HTMLInputElement)?.focus();
              }
            }}
          />
        </Box>
        {errors.joinCode && <Typography variant="body2" sx={{ color: "red" }}>{errors.joinCode}</Typography>}
        {codeSegments[0] === "HCONF25" && (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Checkbox checked={plusExec} onChange={(e) => setPlusExec(e.target.checked)} size="small" color="primary" sx={{ borderRadius: '50%' }} />
              <Typography variant="body2" sx={{ color: 'black' }}>Add Executive Seminar (+$75)</Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Checkbox checked={isVerified} onChange={(e) => setIsVerified(e.target.checked)} size="small" color="primary" sx={{ borderRadius: '50%' }} />
              <Typography variant="body2" sx={{ color: 'black' }}>
                I accept the <a style={{ textDecoration: 'none', color: dark_red }} href="/documents/terms_and_conditions.html" target="_blank">Terms and Conditions.</a>
              </Typography>
            </Box>
            {errors.isVerified && <Typography variant="body2" sx={{ color: 'red' }}>{errors.isVerified}</Typography>}
          </>
        )}
        <Button variant="contained" onClick={handleValidation} sx={{ width: '100%', color: 'white', backgroundColor: dark_red, borderRadius: '3px', marginTop: '15px' }}>Next</Button>
      </Box>
      <Button variant="text" onClick={() => auth.signOut()} sx={{ marginTop: '8px', backgroundColor: 'transparent', color: 'black', width: '100%' }}>Sign out</Button>
    </Box>
  );

  const renderPayment = () => (
    <Box sx={{ width: '300px' }}>
      <Typography variant="h4" sx={{ color: 'black', fontWeight: 'bold', marginBottom: '10px' }}>Payment</Typography>
      <Typography variant="body1" sx={{ color: 'black' }}>
        Please complete payment of ${price + (plusExec ? 75 : 0)} USD to complete registration.
      </Typography>
      <PaymentWidget user={user} joinCode={codeSegments.join("-")} products={plusExec ? { '0d5TXBeIPPCUYJt1MCPg': 1 } : null} onSuccess={() => window.location.reload()} />
      <Button variant="text" color="secondary" onClick={() => setPageState(0)} style={{ marginTop: '8px', backgroundColor: 'transparent', color: 'white' }}>Back</Button>
    </Box>
  );

  const onPreferencesSubmit = () => {
    setShowBackdrop(true);
    window.location.reload();
  };

  useEffect(() => {
    if (showBackdrop) {
      setTimeout(() => {
        setShowBackdrop(false);
      }, 2000);
    }
  }, [showBackdrop]);

  return (
    <Box>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showBackdrop}
        onClick={() => setShowBackdrop(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {pageState === 0 && renderJoinForm()}
      {pageState === 1 && renderPayment()}
      {pageState === 2 && <PreferencesWidget user={user} onSubmit={onPreferencesSubmit} />}
    </Box>
  );
}
