import React, { useState } from 'react';
import { Box, Button, Typography } from "@mui/material";
import { auth } from '../../utils/firebase';
import { useForm } from "react-hook-form";
import CustomInput from "../widgets/CustomInput";
import { dark_red,light_red } from '../../utils/colors';

interface FormData {
  email: string;
  password: string;
}

interface LogInProps {
  setIsSignUp: (isSignUp: boolean) => void;
}

export default function LogIn({ setIsSignUp }: LogInProps) {
  const { register, handleSubmit, formState: { errors } } = useForm<FormData>();
  const [showForgotPassword, setShowForgotPassword] = useState<boolean>(false);

  const handleSignIn = async (formData: FormData) => {
    try {
      await auth.signInWithEmailAndPassword(formData.email, formData.password);
      // User is signed in. You can handle redirection or any other logic here.
    } catch {
      alert("Invalid email or password. Please try again or reset your password. If you are still having issues, please reach out to conference support.")
    }
  };

  const handleForgotPassword = () => {
    // Show the "Forgot Password" input fields
    setShowForgotPassword(true);
  };

  const handleSendPasswordResetEmail = async (formData: FormData) => {
    // Add code here to send a password reset email
    try {
      await auth.sendPasswordResetEmail(formData.email);
      alert("Password reset email sent. Please check your email for instructions.");
      setShowForgotPassword(false); // Hide the "Forgot Password" input fields
    } catch (error) {
      alert("Failed to send the password reset email. Please try again later.");
      console.error(error);
    }
  };

  return (
    <Box sx={{
      width: "300px",
    }}>
      {!showForgotPassword ? (
        <>
          <Typography variant="h4" sx={{
            color: 'black',
            fontWeight: 'bold',
            marginBottom: '10px',
          }}>Log In</Typography>
          <form onSubmit={handleSubmit(handleSignIn)} style={{ width: '100%' }}>
            <CustomInput
              label="Email"
              id="email"
              type="text"
              register={register("email", { required: true })}
              color='secondary'
            />
            {errors.email && <Typography variant="body1" sx={{ color: light_red }}>This field is required.</Typography>}
            <CustomInput
              label="Password"
              id="password"
              type="password"
              register={register("password", { required: true })}
              color="secondary"
            />
            {errors.password && <Typography variant="body1" sx={{ color: light_red }}>This field is required.</Typography>}
            <Button
              variant="text"
              onClick={handleForgotPassword}
              sx={{
                p: '0px',
                textDecoration: 'none',
                color: 'black',
                textTransform: 'none',
                fontSize: '.9rem',
              }}
            >
              Forgot Password?
            </Button>
            <Button variant="contained" type="submit" sx={{ 
              marginTop: '15px', 
              background: dark_red, 
              color: 'white', 
              borderRadius: '3px',
              width: '300px',
              marginBottom: '30px',
            }}>Log In
            </Button>
          </form>
          <Typography component="span" sx={{ color: 'black', fontSize: '.9rem', marginTop: '50px' }}>
            Don't have an account yet?
            <Button
              variant="text"
              onClick={() => setIsSignUp(true)}
              sx={{
                textDecoration: 'none',
                color: 'dark_red',
                fontWeight: 'bold',
                textTransform: 'none',
                fontSize: '.9rem',
                p: '0px',
                marginLeft: '5px',
              }}
            >
              Sign up
            </Button>
          </Typography>

        </>
      ) : (
        <>
          <Typography variant="h4" sx={{
            color: 'black',
            fontWeight: 'bold',
            marginBottom: '10px',
          }}>Forgot Password</Typography>

          <form onSubmit={handleSubmit(handleSendPasswordResetEmail)}>
            <CustomInput
              label="Email"
              id="email"
              type="text"
              register={register("email", { required: true })}
              color='secondary'
            />
            {errors.email && <Typography variant="body1" sx={{ color: light_red }}>This field is required.</Typography>}
            <Button
              variant="contained"
              type="submit"
              style={{ width: '100%', marginTop: '15px', backgroundColor: dark_red, color: 'white', borderRadius: '3px'}}
            >
              Send Email
            </Button>
            <Button
              variant="text"
              color="secondary"
              onClick={() => setShowForgotPassword(false)} // Go back to the sign-in form
              style={{ marginTop: '8px', backgroundColor: 'transparent', color: 'white', width: '100%' }}
            >
              Back to Sign In
            </Button>
          </form>
        </>
      )}
    </Box>
  );
}
