import React, { useEffect, useRef, useState } from 'react';
import { Box, Divider, Paper, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDrag, useDrop } from 'react-dnd';
import { Conference, User } from "../../../utils/types";
import { subscribeToUsersInConf } from '../../../utils/mutations/users';
import { dark_red } from '../../../utils/colors';

interface DragItem {
  id: string;
  index: number;
}

interface AdminConferenceCardProps {
  conf: Conference;
  index: number;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
}

const AdminConferenceCard: React.FC<AdminConferenceCardProps> = ({ conf, index, moveCard }) => {
  const navigate = useNavigate();
  const [registrants, setRegistrants] = useState<number>(0);

  useEffect(() => {
    const unsubscribe = subscribeToUsersInConf(conf.conferenceCode, (users: User[]): void => {
      setRegistrants(users ? users.length : 0);
      console.log(users);
    });

    return () => {
      unsubscribe();
    };
  }, [conf.conferenceCode]);

  const ref = useRef<HTMLDivElement>(null);

  const [, drop] = useDrop<DragItem>({
    accept: 'CARD',
    hover(item) {
      if (!ref.current) return;

      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) return;

      moveCard(dragIndex, hoverIndex);
      item.index = hoverIndex; // Update the dragged item's index to reflect the new position
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: 'CARD',
    item: { id: conf.conferenceCode, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  return (
    <Paper
      ref={ref}
      sx={{
        margin: '10px',
        minHeight: '300px',
        width: '300px',
        opacity: isDragging ? 0.5 : 1,
        cursor: 'move',
        border: isDragging ? '2px dashed gray' : 'none',
        borderRadius: '0px',
      }}
    >
      <Box sx={{ padding: '15px' }}>
        <Typography
          component="div"
          variant="h6"
          onClick={() => navigate(`/ADMIN/${conf.conferenceCode}`)}
          sx={{
            textAlign: 'center',
            cursor: 'pointer',
            color: dark_red,
            fontWeight: 'bold',
          }}
        >
          {conf.conferenceCode}
        </Typography>
        <Divider sx={{ marginY: '10px' }} />
        <Typography variant="subtitle1">{conf.conferenceName}</Typography>
        <Typography variant="subtitle1">{registrants} Registrants</Typography>
        <Typography variant="subtitle1">Prices:</Typography>
        {Object.entries(conf.prices || {}).map(([category, price]) => {
          const displayPrice = price === -Infinity ? 0 : Math.abs(price);

          return (
            <Typography
              key={category}
              variant="subtitle2"
              sx={{
                marginLeft: '20px',
                color: price < 0 ? dark_red : 'inherit',
              }}
            >
              {`${category}: $${displayPrice}`}
            </Typography>
          );
        })}
      </Box>
    </Paper>
  );
};

export default AdminConferenceCard;
